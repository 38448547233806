var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout-pager-view",
    class: _vm.isMobile && 'layout-pager-view-wap'
  }, [_c('div', {
    staticClass: "pager-action pager-prev",
    class: {
      'pager-action-disable': _vm.currentPage == 1
    },
    on: {
      "click": _vm.onClickPrePage
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13.75 5.5L8.25 11L13.75 16.5",
      "stroke": "white",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])]), _vm.totalPage <= 10 ? _vm._l(_vm.totalPage, function (item, index) {
    return _c('div', {
      staticClass: "pager-action",
      class: {
        'pager-action-current': _vm.currentPage == item
      },
      on: {
        "click": function ($event) {
          return _vm.onClickPage(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }) : [_vm._l(_vm.splitPage, function (item, index) {
    return [item[0] === -1 ? _c('div', {
      staticClass: "pager-dot"
    }, [_vm._v(" ... ")]) : _vm._l(item, function (itemPage, index) {
      return _c('div', {
        staticClass: "pager-action",
        class: {
          'pager-action-current': _vm.currentPage == itemPage
        },
        on: {
          "click": function ($event) {
            return _vm.onClickPage(itemPage);
          }
        }
      }, [_vm._v(" " + _vm._s(itemPage) + " ")]);
    })];
  })], _c('div', {
    staticClass: "pager-action pager-next",
    class: {
      'pager-action-disable': _vm.currentPage == _vm.totalPage
    },
    on: {
      "click": _vm.onClickNextPage
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "d": "M8.25 16.5L13.75 11L8.25 5.5",
      "stroke": "white",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };