var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-prompt', {
    attrs: {
      "show": _vm.showInviteBindingDialog,
      "title": _vm.$lang('Please enter and link your inviter\'s information.'),
      "showCancelBtn": false,
      "confirmTxt": _vm.$lang('Ok')
    },
    on: {
      "update:show": function ($event) {
        _vm.showInviteBindingDialog = $event;
      },
      "cancel": _vm.hideDialog,
      "confirm": _vm.hideDialog
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };