import Vue from "vue";
import lang from "@i18n/i18nOption.json";
import web3Wallet from "@web3Wallet/index";
import BigNumber from "bignumber.js";

const {langDefault, langList} = lang;

export default {
    namespaced: true,
    state: {
        isMobile: false,
        isLang: "En",
        setting: {},

        balance: {}, //代币余额
        connect: false,
        isAddress: false,
        netIdError: true,
        netId: null,
        checkWalletHandle: null,
        address: "",
        btnTop: false,
        userInfo: null,
        showInviteBindingDialog: false,
        confirmInviteBinding:false
    },
    mutations: {
        setBtnTop(state, payload) {
            state.btnTop = payload;
        },
        SETLANG: (state, payload) => {
            state.isLang = payload;
        },
        SET_ISMOBILE: (state, payload) => {
            state.isMobile = payload;
        },
        SETSETTING: (state, payload) => {
            state.setting = payload;
        },

        CLEAR_CHECKWALLETHANDLE: (state) => {
            clearInterval(state.checkWalletHandle);
        },
        SET_CHECKWALLETHANDLE: (state, payload) => {
            state.checkWalletHandle = payload;
        },
        CLEARCHECKWALLETHANDLE: (state) => {
            clearInterval(state.checkWalletHandle);
        },
        SET_BALANCE: (state, payload) => {
            // console.log('payload', payload);
            for (const key in payload) {
                Vue.prototype.$set(state.balance, key, payload[key]);
            }
        },
        SET_BNB_BALANCE: (state, payload) => {
            state.bnbBalance = payload;
        },
        SET_CHANGESTATUS: (state, {connect, isAddress, netIdError, netId, address}) => {
            state.connect = connect;
            state.isAddress = isAddress;
            state.netIdError = netIdError;
            state.netId = netId;
            state.address = address;
        },
        SET_UER_INFO: (state, payload) => {
            state.userInfo = payload
        },
        SET_INVITEBINDING_DIALOG: (state, payload) => {
            state.showInviteBindingDialog = payload
        },
        SET_INVITEBINDING_CONFIRM: (state, payload) => {
            state.confirmInviteBinding = payload
        },
    },
    actions: {
        //获取余额
        getBalance: async ({commit}) => {
            try {
                const {value, formatted} = await web3Wallet.getBalance(web3Wallet.address);
                commit("SET_BALANCE", {
                    value: BigNumber(value).toFixed(0),
                    label: formatted,
                });
                // console.log(state.balance);
            } catch (err) {
            }
        },


        //连接web3
        doConnect: async ({commit, dispatch}, bool = false) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const status = await web3Wallet.connect();
                    console.log("doConnect status:", status);
                    commit("SET_CHANGESTATUS", {
                        connect: web3Wallet.connected,
                        isAddress: typeof web3Wallet.address === "string" ? true : false,
                        netIdError: web3Wallet.netIdError,
                        netId: web3Wallet.netId,
                        address: web3Wallet.address,
                    });
                    console.log("doConnect", bool);
                    bool && dispatch("checkWallet");
                    resolve();
                } catch (err) {
                    console.log("doConnect Error: ", err);
                }
            });
        },
        doDisconnect: async ({commit, dispatch}) => {
            try {
                await web3Wallet.disConnect();
                commit("SET_CHANGESTATUS", {
                    connect: web3Wallet.connected,
                    isAddress: typeof web3Wallet.address === "string" ? true : false,
                    netIdError: web3Wallet.netIdError,
                    netId: web3Wallet.netId,
                    address: web3Wallet.address,
                });
            } catch (err) {
                console.log("disConnect Error: ", err);
            }
        },
        checkWallet: async ({commit, dispatch}) => {
            console.log("checkWallet//////");
            web3Wallet.watchAccountChange(async (account) => {
                await web3Wallet.checkNetId();
                console.log("checkWallet account info:", account);
                await dispatch("getBalance");
                commit("SET_CHANGESTATUS", {
                    connect: web3Wallet.connected,
                    netIdError: web3Wallet.netIdError,
                    netId: web3Wallet.netId,
                    isAddress: typeof web3Wallet.address === "string" ? true : false,
                    address: web3Wallet.address,
                });
                // if (account && !account.address) {
                //   // console.log("账号为空,断开钱包并退出登录");
                //   // web3Wallet.disConnect();
                //   commit("login/setIsSign", false, { root: true });
                //   return;
                // }
            });
            web3Wallet.watchNetworkChange(async (_) => {
                await dispatch("getBalance");
                commit("SET_CHANGESTATUS", {
                    connect: web3Wallet.connected,
                    netIdError: web3Wallet.netIdError,
                    netId: web3Wallet.netId,
                    isAddress: typeof web3Wallet.address === "string" ? true : false,
                    address: web3Wallet.address,
                });
                // if(web3Wallet.netIdError && state.isWallet===false) {
                //   commit("login/setIsSign", false, { root: true });
                // }
            });
        },
        refreshUserInfo: async ({commit, dispatch}) => {
            if (web3Wallet.address) {
                let rs = await Vue.prototype.$http.post("stake/info", {owner: web3Wallet.address});
                //let rs = await Vue.prototype.$http.post("stake/info",{owner:"0xbbB6AD2A562BC12a1BCA09EaDaC6d603E1aaE504"});
                commit('SET_UER_INFO', rs.data)
            } else {
                commit('SET_UER_INFO', null)
            }
        },
        changeInviteBindingDialog: ({state, commit, dispatch}, showing) => {
           commit('SET_INVITEBINDING_DIALOG', showing)
        },
        inviteBindingConfrim: ({commit, dispatch}, _bool) => {
            commit('SET_INVITEBINDING_CONFIRM', _bool)
        },
    },
    getters: {
        isWallet: (state) => state.connect && state.isAddress && !state.netIdError,
        balanceLabel: (state) => state.balance.label,
    },
};
