var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout-nft-mall-item",
    class: _vm.isMobile && 'layout-nft-mall-item-wap'
  }, [_c('div', {
    staticClass: "layout-nft-mall-item-image"
  }, [_c('img', {
    attrs: {
      "src": require('@images/nft-mall-item-1.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "nft-item-addr"
  }, [_vm._v(_vm._s(_vm.nftItem.owner?.slice(0, 4) + '***' + _vm.nftItem.owner?.slice(-4)))]), _c('div', {
    staticClass: "nft-item-date"
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.nftItem.trade_time)))]), _c('div', {
    staticClass: "split-line"
  }), _c('div', {
    staticClass: "nft-item-amount"
  }, [_vm._v(" " + _vm._s(_vm.$lang('amount')) + ": " + _vm._s(parseInt(_vm.nftItem.locked_num)) + " ")]), _c('CommonAnimButton', {
    attrs: {
      "disabled": _vm.nftItem?.owner?.toLowerCase() == _vm.address?.toLowerCase()
    },
    on: {
      "click": _vm.onConfirm
    }
  }, [_c('div', {
    staticClass: "nft-item-button"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Purchase')))])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };