/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./RectBalanceNotEnoughDialog.vue?vue&type=template&id=56543084&scoped=true"
import script from "./RectBalanceNotEnoughDialog.vue?vue&type=script&lang=js"
export * from "./RectBalanceNotEnoughDialog.vue?vue&type=script&lang=js"
import style0 from "./RectBalanceNotEnoughDialog.vue?vue&type=style&index=0&id=56543084&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56543084",
  null
  
)

export default component.exports