var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c('div', {
    staticClass: "common-tips-dialog",
    class: _vm.isMobile && 'common-tips-dialog-wap'
  }, [_c('div', {
    staticClass: "shadow",
    on: {
      "click": function ($event) {
        _vm.outerCancel && _vm.cancelFn();
      }
    }
  }), _c('transition', {
    attrs: {
      "appear": ""
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    key: "dialog",
    staticClass: "dialog-main",
    style: {
      width: _vm.isMobile ? '90vw' : '50vw',
      maxWidth: _vm.isMobile ? '90vw' : '36%',
      height: 'auto',
      borderRadius: _vm.remTran(15)
    }
  }, [_c('div', {
    staticClass: "pop-main"
  }, [_vm.$slots['custom-title'] ? [_vm._t("custom-title")] : [_c('div', {
    staticClass: "dialog-title"
  }, [_vm._v(_vm._s(_vm.title))])], _vm.$slots['custom-tips'] ? [_vm._t("custom-tips")] : [_c('div', {
    staticClass: "split-line"
  }), _c('div', {
    staticClass: "dialog-tips",
    domProps: {
      "innerHTML": _vm._s(_vm.tips)
    }
  }), _c('div', {
    staticClass: "split-line"
  })], _vm.$slots['custom-actions'] ? [_vm._t("custom-actions")] : [_vm.hasActions ? _c('div', {
    staticClass: "dialog-actions"
  }, [_c('div', {
    staticClass: "dialog-button btn-confirm",
    on: {
      "click": _vm.confirmFn
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("Confirm")) + " ")]), _c('div', {
    staticClass: "dialog-button btn-cancel",
    on: {
      "click": _vm.cancelFn
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("Cancel")) + " ")])]) : _c('div', {
    staticClass: "dialog-actions"
  }, [_c('div', {
    staticClass: "dialog-button btn-cancel",
    on: {
      "click": _vm.confirmFn
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang("Close")) + " ")])])]], 2)])])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };