export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      if (this.disabled) {
        return;
      }
      this.$emit('click');
    }
  }
};