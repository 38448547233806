var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.visible ? _c('div', {
    staticClass: "loading-overlay"
  }, [_c('div', {
    staticClass: "loading-spinner"
  })]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };