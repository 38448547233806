var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CommonTipsDialog', {
    attrs: {
      "custom-slot-visible": _vm.dialogVisible
    },
    on: {
      "confirm": function ($event) {
        return _vm.$emit('cancel');
      }
    },
    scopedSlots: _vm._u([{
      key: "custom-title",
      fn: function () {
        return [_c('div', {
          staticClass: "nft-record-top-line"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v(" " + _vm._s(_vm.$lang('Account record')) + " ")]), _vm.showFilter ? _c('div', {
          staticClass: "filter-popup"
        }, [_c('div', {
          staticClass: "filter-popup-item",
          on: {
            "click": function ($event) {
              return _vm.hideFilterMenu('rect');
            }
          }
        }, [_vm._v("RECT")]), _c('div', {
          staticClass: "filter-popup-item",
          on: {
            "click": function ($event) {
              return _vm.hideFilterMenu('usdt');
            }
          }
        }, [_vm._v("USDT")])]) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "custom-tips",
      fn: function () {
        return [_c('div', {
          staticClass: "nft-record-content"
        }, [_c('div', {
          staticClass: "tab-header"
        }, [_c('div', {
          staticClass: "tab-header-item",
          class: _vm.tabType == 1 ? 'tab-header-item-selected' : '',
          on: {
            "click": function ($event) {
              return _vm.onTabChange(1);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$lang('Recharge')) + " ")]), _c('div', {
          staticClass: "tab-header-item",
          class: _vm.tabType == 2 ? 'tab-header-item-selected' : '',
          on: {
            "click": function ($event) {
              return _vm.onTabChange(2);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$lang('Withdraw')) + " ")]), _c('div', {
          staticClass: "tab-header-item",
          class: _vm.tabType == 3 ? 'tab-header-item-selected' : '',
          on: {
            "click": function ($event) {
              return _vm.onTabChange(3);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$lang('Transfer')) + " ")]), _c('div', {
          staticClass: "tab-header-item",
          class: _vm.tabType == 4 ? 'tab-header-item-selected' : '',
          on: {
            "click": function ($event) {
              return _vm.onTabChange(4);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$lang('消费')) + " ")])]), _c('div', {
          ref: "myScroll",
          staticClass: "tab-content",
          on: {
            "scroll": _vm.checkScroll
          }
        }, [!_vm.recordList || _vm.recordList.length <= 0 ? [!_vm.logLoading ? _c('EmptyLayout') : _vm._e()] : _vm._e(), _vm.tabType == 1 ? _vm._l(_vm.recordList, function (item, index) {
          return _c('div', {
            staticClass: "tab-content-item"
          }, [_c('div', {
            staticClass: "tab-content-item-left"
          }, [_c('div', {
            staticClass: "tab-content-item-text-1"
          }, [_vm._v(_vm._s(item.formatAmount) + " RECT")]), _c('div', {
            staticClass: "tab-content-item-text-3"
          }, [_vm._v(" " + _vm._s(item.showDateFormat) + " ")])]), _c('div', {
            staticClass: "tab-content-item-text-2"
          }, [_vm._v(_vm._s(_vm.$lang('Recharge')))])]);
        }) : _vm.tabType == 2 ? _vm._l(_vm.recordList, function (item, index) {
          return _c('div', {
            staticClass: "tab-content-item"
          }, [_c('div', {
            staticClass: "tab-content-item-left"
          }, [_c('div', {
            staticClass: "tab-content-item-text-1"
          }, [_vm._v(_vm._s(item.formatAmount) + " RECT")]), _c('div', {
            staticClass: "tab-content-item-text-3"
          }, [_vm._v(" " + _vm._s(item.showDateFormat) + " ")])]), _c('div', {
            staticClass: "tab-content-item-text-2",
            class: `status-${item.status}`
          }, [item.status == 0 ? [item.is_lock == 1 ? [_vm._v(_vm._s(_vm.$lang('Process')))] : _vm._e(), item.is_lock == 0 ? [_c('div', {
            staticClass: "btn",
            on: {
              "click": function ($event) {
                return _vm.withdrawOldOrder(item);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$lang(`Withdraw`)) + " ")])] : _vm._e()] : _vm._e(), item.status == 1 ? [_vm._v(" " + _vm._s(_vm.$lang('Processing')) + " ")] : _vm._e(), item.status == 2 ? [_vm._v(" " + _vm._s(_vm.$lang('Success')) + " ")] : _vm._e(), item.status == 3 ? [_vm._v(" " + _vm._s(_vm.$lang('Failure')) + " ")] : _vm._e()], 2)]);
        }) : _vm._e(), _vm.tabType == 3 ? _vm._l(_vm.recordList, function (item, index) {
          return _c('div', {
            staticClass: "tab-content-item"
          }, [_c('div', {
            staticClass: "tab-content-item-left"
          }, [_c('div', {
            staticClass: "tab-content-item-text-1"
          }, [_vm._v(_vm._s(item.formatAmount) + " RECT")]), _c('div', {
            staticClass: "tab-content-item-text-3"
          }, [_vm._v(" " + _vm._s(item.showDateFormat) + " ")])]), _c('div', {
            staticClass: "tab-content-item-text-2"
          }, [_vm._v(_vm._s(_vm.$lang('Transfer')))])]);
        }) : _vm._e(), _vm.tabType == 4 ? _vm._l(_vm.recordList, function (item, index) {
          return _c('div', {
            staticClass: "tab-content-item"
          }, [_c('div', {
            staticClass: "tab-content-item-left"
          }, [_c('div', {
            staticClass: "tab-content-item-text-1"
          }, [_vm._v(_vm._s(item.formatAmount) + " RECT")]), _c('div', {
            staticClass: "tab-content-item-text-3"
          }, [_vm._v(" " + _vm._s(item.showDateFormat) + " ")])]), item.action == 'buy' ? _c('div', {
            staticClass: "tab-content-item-text-2"
          }, [_vm._v(_vm._s(_vm.$lang('购买手续费')))]) : item.action == 'sell' ? _c('div', {
            staticClass: "tab-content-item-text-2"
          }, [_vm._v(_vm._s(_vm.$lang('出售收入')))]) : item.action == 'transferOut' ? _c('div', {
            staticClass: "tab-content-item-text-2"
          }, [_vm._v(_vm._s(_vm.$lang('转移手续费')) + " ")]) : _vm._e()]);
        }) : _vm._e(), _vm.logLoading ? _c('div', {
          staticClass: "loadingList"
        }, [_c('div', {
          staticClass: "loading-list"
        }, _vm._l(3, function (_) {
          return _c('div');
        }), 0)]) : _vm._e()], 2)])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };