var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout-ranking-bottom",
    class: _vm.isMobile && 'layout-ranking-bottom-wap'
  }, [_c('div', {
    staticClass: "layout-ranking-bottom-left"
  }, [_c('div', {
    staticClass: "title-1"
  }, [_vm._v(" " + _vm._s(_vm.$lang('贡献者奖池')) + " ")]), _c('div', {
    staticClass: "layout-ranking-bottom-left-ball"
  }, [_c('div', {
    staticClass: "ball-title-1"
  }, [_vm._v(_vm._s(_vm.poolTotalRect))]), _c('div', {
    staticClass: "ball-title-2"
  }, [_vm._v("rect")])]), _c('div', {
    staticClass: "layout-ranking-bottom-left-bg"
  })]), _c('div', {
    staticClass: "layout-ranking-bottom-right"
  }, [_c('div', {
    staticClass: "title-3"
  }, [_vm._v(" " + _vm._s(_vm.$lang('发放记录')) + " ")]), _c('div', {
    ref: "myScroll",
    staticClass: "layout-ranking-bottom-right-content",
    on: {
      "scroll": _vm.checkScroll
    }
  }, [_vm._l(_vm.recordList, function (item, index) {
    return _c('div', {
      staticClass: "record-bottom-line-item"
    }, [_c('div', {
      staticClass: "record-bottom-line-item-title"
    }, [_vm._v(" " + _vm._s(_vm.formatRectCoinAmount(item.amount)) + " RECT ")]), _c('div', {
      staticClass: "record-bottom-line-item-line"
    }, [_c('div', {}, [_vm._v(_vm._s(_vm.$lang('发放时间')))]), _c('div', {}, [_vm._v(_vm._s(item.showDateFormat))])]), _c('div', {
      staticClass: "record-bottom-line-item-line"
    }, [_c('div', {}, [_vm._v(_vm._s(_vm.$lang('地址')))]), _c('div', {}, [_vm._v(_vm._s(_vm._f("fmt_address")(item.staker, 12)))])]), _c('div', {
      staticClass: "record-bottom-line-item-line"
    }, [_c('div', {}, [_vm._v(_vm._s(_vm.$lang('销毁数量')))]), _c('div', {}, [_vm._v(_vm._s(_vm.formatRectCoinAmount(item.burn_amount)))])])]);
  }), _vm.logLoading ? _c('div', {
    staticClass: "loadingList"
  }, [_c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0)]) : _vm._e()], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };