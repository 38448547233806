var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-center",
    class: _vm.isMobile && 'wap-content-center'
  }, [_c('div', {
    staticClass: "stake-box-left"
  }, [_c('img', {
    attrs: {
      "src": require('@images/center-box-image-2.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "stake-box-right"
  }, [_c('div', {
    staticClass: "stake-box-right-tile-line"
  }, [_c('div', {
    staticClass: "stake-box-title"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Purchase')) + " ")]), _c('div', {
    staticClass: "stake-box-sub"
  }, [_vm._v(" " + _vm._s(_vm.$lang('On sale')) + ": " + _vm._s(_vm.nftTotalSalNum) + " "), _c('span', {
    staticClass: "stake-box-sub-yellow"
  }, [_vm._v("(" + _vm._s(_vm.nftSalePrice) + "RECT/piece)")])])]), _c('div', {
    staticClass: "hor-line"
  }), _c('div', {
    staticClass: "stake-input-line"
  }, [_c('div', {
    staticClass: "stake-button",
    on: {
      "click": _vm.toNftMall
    }
  }, [_vm._v(_vm._s(_vm.$lang('Go to Purchase')))])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };