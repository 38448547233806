import Vue from "vue";
import vailcode from "@utils/errcode";
import lang from "@i18n/i18nOption.json";
const { langDefault, langList } = lang;
import web3Wallet from "@web3Wallet/index";
import web3Config from "@Web3WalletConfig/config";
export default {
  namespaced: true,
  state: {
    statData:{
      firstInfo:{},
      secondInfo:{}
    },
    firstCondition:{
      page:1,
      pageSize:10
    },
    firstListData:{
      total:0
    },
    secondCondition:{
      page:1,
      pageSize:10
    },
    secondListData:{
      total:0
    },
  },
  mutations: {
    setStatData:(state, payload) => {
        state.statData  = payload
    },
    setFirstCondition:(state, payload) => {
        for(let key in payload){
          state.firstCondition[key]  = payload[key]
        }
    },
    setFirstListData:(state, payload) => {
      state.firstListData = payload;
    },
    setSecondCondition:(state, payload) => {
      for(let key in payload){
        state.secondCondition[key]  = payload[key]
      }
    },
    setSecondListData:(state, payload) => {
      state.secondListData = payload;
    },
  },
  actions: {
     getStatData:async ({ commit, dispatch }) => {
        let inviter =   web3Wallet.address ? web3Wallet.address: "0x0000000000000000000000000000000000000000"
        let rs = await Vue.prototype.$http.post("/stat",{inviter});
        vailcode(rs,()=>{
            commit("setStatData",rs.data);
        })
     },
     getFirstListData:async ({ state, commit, dispatch }) => {
      let inviter = web3Wallet.address ? web3Wallet.address: "0x0000000000000000000000000000000000000000"
      state.firstCondition.inviter = inviter;
        let rs = await Vue.prototype.$http.post("/firstlist",state.firstCondition);
        vailcode(rs,()=>{
            commit("setFirstListData",rs.data);
        })
     }
     ,
     getSecondListData:async ({state, commit, dispatch }) => {
      let inviter = web3Wallet.address ? web3Wallet.address: "0x0000000000000000000000000000000000000000"
      state.secondCondition.inviter = inviter;
        let rs = await Vue.prototype.$http.post("/secondlist",state.secondCondition);
        vailcode(rs,()=>{
            commit("setSecondListData",rs.data);
        })
     }
  }
}