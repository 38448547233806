import Vue from "vue";
import CommonTipsDialog from "@components/CommonTipsDialog.vue";
import rectBalanceNotEnoughDialog from "@components/RectBalanceNotEnoughDialog.vue";

export let index = 1000;

export const cache = new Set();

// https://juejin.cn/post/7188056420212801591
export function openDialog(component) {
    const div = document.createElement("div");
    const el = document.createElement("div");

    const id = 'dialog-' + Math.random();

    div.appendChild(el);
    document.body.appendChild(div);

    const ComponentConstructor = Vue.extend(component);
    return (propsData = {}, parent = undefined) => {
        let instance = new ComponentConstructor({
            propsData,
            parent,
        }).$mount(el);

        const destroyDialog = () => {
            if (cache.has(id)) return;
            if (instance && div.parentNode) {
                cache.add(id);
                instance.visible = false;
                // 延时是为了在关闭动画执行完毕后卸载组件
                setTimeout(() => {
                    cache.delete(id);
                    instance.$destroy();
                    instance = null;
                    div.parentNode && div.parentNode.removeChild(div);
                }, 1000);
            }
        };

        // visible控制
        if (instance.visible !== undefined) {
            // 支持sync/v-model
            instance.$watch("visible", (val) => {
                !val && destroyDialog();
            });
            Vue.nextTick(() => (instance.visible = true));
        }

        return new Promise((resolve, reject) => {
            // emit 一个 done 事件关闭
            instance.$once("confirm", (data = '') => {
                destroyDialog();
                resolve(data);
            });
            // emit 一个 cancel 事件取消
            instance.$once("cancel", (data = '') => {
                destroyDialog();
                reject(data);
            });
        });
    };
}

// 简单弹窗使用此。 不依赖store.所有数据全部props传入。
export function showCommonTipsDialog(title, tips, hasActions, outerCancel) {
    return openDialog(CommonTipsDialog)({title, tips, hasActions, outerCancel});
}

export function showRectBalanceNotEnoughDialog() {
    return openDialog(rectBalanceNotEnoughDialog)({});
}

//显示错误提示，不用处理点击事件　
export function showCommonTipsErrorDialog(title, tips) {
    showCommonTipsDialog(title, tips)
        .then(() => {
        })
        .catch(() => {
        })
}