import Vue from 'vue';
import Loading from './CommonLoading.vue';

// 创建一个全局的事件总线
const loadingInstance = new Vue({
    methods: {
        show() {
            this.$emit('show');
        },
        hide() {
            this.$emit('hide');
        }
    }
});

// 挂载全局加载组件
const loadingConstructor = Vue.extend(Loading);
const instance = new loadingConstructor();
instance.$mount();
document.body.appendChild(instance.$el);

// 监听全局事件总线的 show 和 hide 事件
loadingInstance.$on('show', () => {
    instance.show();
});
loadingInstance.$on('hide', () => {
    instance.hide();
});

// 将加载实例挂载到 Vue 原型上
Vue.prototype.$loading = loadingInstance;

export default loadingInstance;