export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    tips: {
      type: String,
      default: ''
    },
    outerCancel: {
      type: Boolean,
      default: false
    },
    hasActions: {
      type: Boolean,
      default: false
    },
    customSlotVisible: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  destroyed() {
    window.removeEventListener('resize', this.checkMobile);
  },
  computed: {},
  data() {
    return {
      visible: false,
      isMobile: false
    };
  },
  watch: {
    customSlotVisible(newVal, oldVal) {
      console.log(newVal, ' customSlotVisible');
      if (newVal) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    }
  },
  methods: {
    checkMobile() {
      let width = document.documentElement.clientWidth || document.body.clientWidth;
      if (width <= 980) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    cancelFn() {
      this.$emit("cancel");
    },
    confirmFn() {
      this.$emit("confirm");
    }
  }
};