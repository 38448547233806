var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout-center",
    class: _vm.isMobile && 'layout-center-wap'
  }, [_c('div', {
    staticClass: "layout-center-content"
  }, [_c('div', {
    staticClass: "layout-center-top"
  }, [_c('div', {
    staticClass: "top-nft-box-image"
  }, [_c('video', {
    attrs: {
      "autoplay": "",
      "loop": "",
      "muted": "",
      "playsinline": ""
    },
    domProps: {
      "muted": true
    }
  }, [_c('source', {
    attrs: {
      "src": require('@images/video.mp4'),
      "type": "video/mp4"
    }
  })])]), _c('div', {
    staticClass: "top-nft-box-title"
  }, [_vm._v(_vm._s(_vm.$lang('Equity NFT')))]), _c('div', {
    staticClass: "top-nft-box-sub-line"
  }, [_c('div', {
    staticClass: "top-nft-box-sub"
  }, [_vm._v(_vm._s(_vm.$lang('Currently owns')) + " : " + _vm._s(_vm.nftAvailableNum))]), _c('div', {
    staticClass: "top-nft-box-button",
    on: {
      "click": _vm.showNftRecord
    }
  }, [_vm._v(_vm._s(_vm.$lang('Record')))])]), _c('CenterNftRecordDialog', {
    attrs: {
      "dialog-visible": _vm.recordDialogVisible
    },
    on: {
      "cancel": _vm.hideNftRecord
    }
  })], 1), _c('div', {
    staticClass: "layout-box-all"
  }, [_c('CenterBoxStake', {
    attrs: {
      "nft-available-num": _vm.nftAvailableNum
    },
    on: {
      "stakeSuccess": _vm.stakeSuccess
    }
  }), _c('CenterBoxPurchase', {
    attrs: {
      "nft-total-sal-num": _vm.nftTotalSalNum,
      "nft-sale-price": _vm.nftSalePrice
    }
  }), _c('CenterBoxTransfer', {
    attrs: {
      "nft-available-num": _vm.nftAvailableNum
    },
    on: {
      "transferSuccess": _vm.transferSuccess
    }
  }), _c('CenterBoxConsignment', {
    attrs: {
      "nft-available-num": _vm.nftAvailableNum,
      "nft-locked-num": _vm.nftLockedNum
    },
    on: {
      "consignmentSuccess": _vm.consignmentSuccess,
      "removeSuccess": _vm.consignmentSuccess
    }
  })], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };