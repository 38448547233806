import Vue from "vue";
import vailcode from "@utils/errcode";
import { createNamespacedHelpers } from "vuex";
import moment from "moment/moment";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  props: {
    dialogVisible: Boolean
  },
  data() {
    return {
      recordList: [],
      logLoading: false,
      logHasMore: false,
      page: 1,
      showFilter: false,
      filterType: ''
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "connect", "address"])
  },
  watch: {
    dialogVisible: {
      handler(newVal, oldVal) {
        this.page = 1;
        if (newVal) {
          this.onLoadData(1);
        }
      },
      immediate: true
    }
  },
  methods: {
    checkScroll() {
      const scrollHeight = this.$refs.myScroll.scrollHeight;
      const scrollTop = this.$refs.myScroll.scrollTop;
      const clientHeight = this.$refs.myScroll.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        // 接近底部时触发加载
        if (this.logHasMore && !this.logLoading) {
          let page = this.page;
          this.onLoadData(page + 1);
        }
      }
    },
    showFilterMenu() {
      this.showFilter = !this.showFilter;
    },
    hideFilterMenu(type) {
      this.filterType = type;
      this.showFilter = false;
      this.onLoadData(1);
    },
    async onLoadData(page) {
      if (!this.address) {
        return;
      }
      if (this.logLoading) {
        return;
      }
      this.logLoading = true;
      this.page = page;
      let params = {
        page: page,
        pageSize: 15,
        "owner": this.address,
        "action": this.filterType
      };
      let rs = await Vue.prototype.$http.post("nft/logs", params);
      vailcode(rs, () => {
        rs.data.list.map(item => {
          console.log(item);
          item.showDateFormat = moment(item.created_at).format('yyyy-MM-DD HH:mm:ss');
        });
        if (rs.data.pageNo == 1) {
          this.recordList = rs.data.list;
        } else {
          this.recordList = this.recordList.concat(rs.data.list);
        }
        if (rs.data.totalPage > rs.data.pageNo) {
          this.logHasMore = true;
        } else {
          this.logHasMore = false;
        }
        this.logLoading = false;
      }, () => {
        this.logLoading = false;
      });
    },
    actionName(action) {
      if (action == 'map') {
        return this.$lang('Mapping');
      } else if (action == 'stake') {
        return this.$lang('stake');
      } else if (action == 'transferIn') {
        return this.$lang('transferIn');
      } else if (action == 'transferOut') {
        return this.$lang('transferOut');
      } else if (action == 'sale') {
        return this.$lang('sale');
      } else if (action == 'unsale') {
        return this.$lang('unsale');
      } else if (action == 'sell') {
        return this.$lang('sell');
      } else if (action == 'buy') {
        return this.$lang('buy');
      }
    }
  }
};