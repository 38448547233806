var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout-ranking",
    class: _vm.isMobile && 'layout-ranking-wap'
  }, [_c('div', {
    staticClass: "layout-ranking-content"
  }, [_c('div', {
    staticClass: "layout-ranking-title",
    domProps: {
      "innerHTML": _vm._s(_vm.$lang('加入Reflection并<span>获得奖励</span>'))
    }
  }), _c('div', {
    staticClass: "layout-ranking-top"
  }, [_c('LayoutRankingJianSheZhe'), _c('LayoutRankingDevote')], 1), _c('LayoutRankingBottom')], 1), _c('div', {
    staticClass: "layout-ranking-bg"
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };