var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout-profile",
    class: _vm.isMobile && 'layout-profile-wap'
  }, [_c('div', {
    staticClass: "layout-profile-content"
  }, [_c('div', {
    staticClass: "layout-profile-wrapper"
  }, [_c('div', {
    staticClass: "profile-left"
  }, [_c('div', {
    staticClass: "profile-avatar"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/profile-avatar.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "profile-address"
  }, [_vm._v(" " + _vm._s(_vm._f("fmt_address")(_vm.address, 12)) + " ")])]), _c('div', {
    staticClass: "profile-right"
  }, [_c('div', {
    staticClass: "profile-right-top"
  }, [_c('div', {
    staticClass: "profile-right-top-title"
  }, [_vm._v(_vm._s(_vm.$lang('Account balance')))]), _c('div', {
    staticClass: "profile-right-top-button",
    on: {
      "click": function ($event) {
        _vm.showDialogProfileRecord = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Record')))]), _c('ProfileRecordDialog', {
    attrs: {
      "dialog-visible": _vm.showDialogProfileRecord
    },
    on: {
      "cancel": function ($event) {
        _vm.showDialogProfileRecord = false;
      }
    }
  })], 1), _c('div', {
    staticClass: "profile-right-line"
  }, [_c('div', {
    staticClass: "profile-right-line-balance"
  }, [_vm._v(_vm._s(_vm.accountRectBalance))]), _c('div', {
    staticClass: "profile-right-line-coin"
  }, [_vm._v("RECT")])])])]), _c('div', {
    staticClass: "layout-profile-action"
  }, [_c('div', {
    staticClass: "layout-profile-action-item"
  }, [_c('CommonAnimButton', {
    on: {
      "click": function ($event) {
        _vm.showDialogTransfer = true;
      }
    }
  }, [_c('div', {
    staticClass: "profile-action-button"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$lang('Transfer')))]), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "21",
      "viewBox": "0 0 16 21",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M7.12419 0.897739L15.6095 9.53977C16.1302 10.0701 16.1302 10.9299 15.6095 11.4602L7.12419 20.1023C6.6035 20.6326 5.75928 20.6326 5.23858 20.1023C4.71788 19.5719 4.71788 18.7121 5.23858 18.1818L11.4477 11.858H0V9.14204H11.4477L5.23858 2.81819C4.71788 2.28787 4.71788 1.42806 5.23858 0.897739C5.75928 0.36742 6.6035 0.36742 7.12419 0.897739Z",
      "fill": "white"
    }
  })])])]), _c('div', {
    staticClass: "layout-profile-action-item-tips"
  }, [_c('span', {
    staticClass: "dot"
  }), _c('span', [_vm._v(_vm._s(_vm.$lang('收益账户划转到市场账户')) + " ")])])], 1), _c('ProfileDialogTransfer', {
    attrs: {
      "dialog-visible": _vm.showDialogTransfer
    },
    on: {
      "cancel": _vm.confirmTransfer,
      "confirm": _vm.confirmTransfer
    }
  }), _c('div', {
    staticClass: "layout-profile-action-item"
  }, [_c('CommonAnimButton', {
    on: {
      "click": function ($event) {
        _vm.showDialogWithdraw = true;
      }
    }
  }, [_c('div', {
    staticClass: "profile-action-button"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$lang('Withdraw')))]), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "21",
      "viewBox": "0 0 16 21",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M7.12419 0.897739L15.6095 9.53977C16.1302 10.0701 16.1302 10.9299 15.6095 11.4602L7.12419 20.1023C6.6035 20.6326 5.75928 20.6326 5.23858 20.1023C4.71788 19.5719 4.71788 18.7121 5.23858 18.1818L11.4477 11.858H0V9.14204H11.4477L5.23858 2.81819C4.71788 2.28787 4.71788 1.42806 5.23858 0.897739C5.75928 0.36742 6.6035 0.36742 7.12419 0.897739Z",
      "fill": "white"
    }
  })])])]), _c('div', {
    staticClass: "layout-profile-action-item-tips"
  }, [_c('span', {
    staticClass: "dot"
  }), _c('span', [_vm._v(_vm._s(_vm.$lang('市场账户提取到链上钱包')) + " ")])])], 1), _c('ProfileDialogWithdraw', {
    attrs: {
      "dialog-visible": _vm.showDialogWithdraw
    },
    on: {
      "cancel": _vm.confirmWithdraw,
      "confirm": _vm.confirmWithdraw
    }
  }), _c('div', {
    staticClass: "layout-profile-action-item"
  }, [_c('CommonAnimButton', {
    on: {
      "click": function ($event) {
        _vm.showDialogRecharge = true;
      }
    }
  }, [_c('div', {
    staticClass: "profile-action-button"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$lang('Recharge')))]), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "21",
      "viewBox": "0 0 16 21",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M7.12419 0.897739L15.6095 9.53977C16.1302 10.0701 16.1302 10.9299 15.6095 11.4602L7.12419 20.1023C6.6035 20.6326 5.75928 20.6326 5.23858 20.1023C4.71788 19.5719 4.71788 18.7121 5.23858 18.1818L11.4477 11.858H0V9.14204H11.4477L5.23858 2.81819C4.71788 2.28787 4.71788 1.42806 5.23858 0.897739C5.75928 0.36742 6.6035 0.36742 7.12419 0.897739Z",
      "fill": "white"
    }
  })])])]), _c('div', {
    staticClass: "layout-profile-action-item-tips"
  }, [_c('span', {
    staticClass: "dot"
  }), _c('span', [_vm._v(_vm._s(_vm.$lang('链上钱包充值到市场账户')) + " ")])])], 1), _c('ProfileDialogRecharge', {
    attrs: {
      "dialog-visible": _vm.showDialogRecharge
    },
    on: {
      "cancel": _vm.confirmRecharge,
      "confirm": _vm.confirmRecharge
    }
  })], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };