import { createNamespacedHelpers } from "vuex";
import loading from "@components/Loading";
const walletVuex = createNamespacedHelpers("wallet");
const nodeVuex = createNamespacedHelpers("node");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "connect", "address"]),
    ...nodeVuex.mapState(["showRecordDialog", "recordList", "condition", "logLoading", "logHasMore"])
  },
  data() {
    return {};
  },
  watch: {
    showRecordDialog() {
      this.onLoadData(1);
    }
  },
  methods: {
    ...nodeVuex.mapActions(["getNodeList", "changeRecordDialog"]),
    ...nodeVuex.mapMutations(["setCondition"]),
    checkScroll() {
      const scrollHeight = this.$refs.myScroll.scrollHeight;
      const scrollTop = this.$refs.myScroll.scrollTop;
      const clientHeight = this.$refs.myScroll.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        // 接近底部时触发加载
        if (this.logHasMore && !this.logLoading) {
          let page = this.condition.page;
          this.onLoadData(page + 1);
        }
      }
    },
    onLoadData(page) {
      this.setCondition({
        "owner": this.address,
        "pageSize": 15,
        "page": page
      });
      this.getNodeList([() => {}, () => {}]);
    },
    showDialog() {
      this.changeRecordDialog(true);
      this.onLoadData(1);
    }
  }
};