import { createNamespacedHelpers } from "vuex";
import CenterBoxStake from "@components/CenterBoxStake.vue";
import CenterBoxConsignment from "@components/CenterBoxConsignment.vue";
import Vue from "vue";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    CenterBoxConsignment,
    CenterBoxStake
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  data() {
    return {
      recordDialogVisible: false,
      nftAvailableNum: 0,
      nftTotalSalNum: 0,
      nftSalePrice: 0,
      nftLockedNum: 0
    };
  },
  watch: {
    address: {
      handler(newVal, oldVal) {
        this.initUserCenterData();
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.initUserCenterData();
  },
  methods: {
    showNftRecord() {
      this.recordDialogVisible = true;
    },
    hideNftRecord() {
      this.recordDialogVisible = false;
    },
    stakeSuccess() {
      console.log('stake success');
      this.initUserCenterData();
    },
    transferSuccess() {
      console.log('transferSuccess');
      this.initUserCenterData();
    },
    consignmentSuccess() {
      console.log('consignmentSuccess');
      this.initUserCenterData();
    },
    initUserCenterData() {
      if (!this.address) {
        return;
      }
      this.$loading.show();
      let params = {
        owner: this.address
      };
      Vue.prototype.$http.post("nft/info", params).then(data => {
        // console.log(data)

        data = data.data;
        this.nftAvailableNum = data.nft_num;
        this.nftLockedNum = data.locked_num;
        this.nftTotalSalNum = data.total_sale_num;
        this.nftSalePrice = data.price;
      }).catch(e => {}).finally(() => {
        this.$loading.hide();
      });
    }
  }
};