import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  data() {
    return {};
  },
  watch: {
    address: {
      handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  mounted() {},
  methods: {}
};