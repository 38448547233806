var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CommonTipsDialog', {
    attrs: {
      "custom-slot-visible": _vm.dialogVisible,
      "has-actions": true,
      "title": _vm.$lang('Enter purchase amount')
    },
    on: {
      "cancel": function ($event) {
        return _vm.$emit('cancel');
      },
      "confirm": _vm.onConfirm
    },
    scopedSlots: _vm._u([{
      key: "custom-tips",
      fn: function () {
        return [_c('div', {
          staticClass: "nft-purchase-content"
        }, [_c('div', {
          staticClass: "nft-purchase-content-title",
          domProps: {
            "innerHTML": _vm._s(_vm.titleFormat)
          }
        }), _c('div', {
          staticClass: "nft-purchase-content-input"
        }, [_c('InputNumCalViewNftPurchase', {
          attrs: {
            "input-min": 1,
            "input-max": _vm.nftItem?.locked_num || 0
          },
          on: {
            "updateInputValue": _vm.updateInputNumber
          }
        })], 1), _c('div', {
          staticClass: "nft-purchase-content-line"
        }, [_c('div', {
          staticClass: "nft-purchase-content-line-title"
        }, [_vm._v(" " + _vm._s(_vm.$lang('available balance')) + " ")]), _c('div', {
          staticClass: "nft-purchase-content-line-value"
        }, [_vm._v(" " + _vm._s(_vm.accountRectBalance) + "RECT ")])]), _c('div', {
          staticClass: "nft-purchase-content-line"
        }, [_c('div', {
          staticClass: "nft-purchase-content-line-title"
        }, [_vm._v(" " + _vm._s(_vm.$lang('unit price')) + " ")]), _c('div', {
          staticClass: "nft-purchase-content-line-value"
        }, [_vm._v(" " + _vm._s(_vm.nftSalePrice) + "RECT/piece ")])]), _c('div', {
          staticClass: "nft-purchase-content-line"
        }, [_c('div', {
          staticClass: "nft-purchase-content-line-title nft-purchase-content-line-title-2"
        }, [_vm._v(" " + _vm._s(_vm.$lang('total price')) + " ")]), _c('div', {
          staticClass: "nft-purchase-content-line-value nft-purchase-content-line-value-2"
        }, [_vm._v(" " + _vm._s(_vm.inputNumber * _vm.nftSalePrice) + "RECT ")])])])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };