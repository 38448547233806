import { createNamespacedHelpers } from "vuex";
import LayoutRankingJianSheZhe from "@components/LayoutRankingJianSheZhe.vue";
import LayoutRankingBottom from "@components/LayoutRankingBottom.vue";
import LayoutRankingDevote from "@components/LayoutRankingDevote.vue";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    LayoutRankingDevote,
    LayoutRankingBottom,
    LayoutRankingJianSheZhe
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  data() {
    return {};
  },
  watch: {
    address: {
      handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  mounted() {},
  methods: {}
};