import Vue from "vue";
import vailcode from "@utils/errcode";

import web3Wallet from "@web3Wallet/index";
import web3Config from "@Web3WalletConfig/config";
import BigNumber from "bignumber.js";
export default {
    namespaced: true,
    state: {
        nftSellInfo:{},
        isLoading: false,
        isApprove:true,
    },
    mutations: {
        setNftSellInfo: (state, payload) => {
            state.nftSellInfo = payload;
        },
        setIsLoading: (state, payload) => {
            state.isLoading = payload
        },
        setApprove:(state, payload) => {
            state.isApprove  = payload
        },
    },
    actions: {
        getNftBaseInfo:async ({ commit, dispatch },addr) => {
            return new Promise(async (resolve, reject) => {
                try { 
                    addr =  addr ||  "0x0000000000000000000000000000000000000000"
                    const res = await web3Wallet.contract('mint').call("getBaseInfo",[addr])
                    console.log(11111,res)
                    const baseInfo = {
                        userMint:res._balance,
                        mintLimit:res._mintLimit,
                        mintTotal:res._mintTotal,
                        openTotalMint:res._openTotalMint,
                        nowTime:res._nowTime,
                        startTime:res._startTime,
                        startPrice:res._startPrice,
                        startPrice_label:new BigNumber(res._startPrice).div(10**web3Config.usdt_decimals).toFixed(2),
                        totalSupply:res._totalSupply,
                        progress:(res._mintTotal*100 / res._totalSupply).toFixed(0)
                    }
           
                    commit("setNftSellInfo",baseInfo)
                    resolve()
                } catch (err) {
                    console.log("getNftBaseInfo===", err);
                    resolve()
                }
           });
        },
        calcPrice:async ({ commit, dispatch },[num,callback]) => {
            try{
                commit("setIsLoading", true);
                const  res =  await web3Wallet.contract('mint').call("calcPrice",[num])
                commit("setIsLoading", false);
                console.log("calcPrice===",res)
                typeof(callback) == 'function' && callback(res)
            }
            catch (err) {
                console.log("calcPrice===", err);
                commit("setIsLoading", false);
            }
        },
        checkApprove:async ({ state, commit, rootState },price) => {
            const params = [web3Wallet.address, web3Config.mint_contract];
            console.log("params==",web3Config.usdt_address, params);
            commit("setIsLoading", true);
            try{
              const res = await web3Wallet.contract("token",web3Config.usdt_address).call("allowance", params);
              commit("setIsLoading", false);
              console.log("checkApprove===",res);
              if(new BigNumber(res).gte(price)){
                commit("setApprove",true);
              }else{
                commit("setApprove",false);
              }
            }
            catch (err) {
                commit("setIsLoading", false);
            }
          },
          doApprove:async ({ state, commit, rootState,dispatch }, [price,callback]) => {
            commit("setIsLoading", true);
            try{
              const params = [web3Config.mint_contract,price];
              console.log("params==",web3Config.usdt_address, params);
              const res = await web3Wallet.contract("token",web3Config.usdt_address).send("approve", params);
              dispatch('checkApprove',price);
              commit("setIsLoading", false);
              typeof callback == 'function' && callback()
            }
            catch (err) {
              commit("setIsLoading", false);
            }
          },
          doBuy : async ({ state, commit, rootState,dispatch }, [addr,buyNum,invite,callback]) => {
            commit("setIsLoading", true);
            try{
                if(!buyNum){
                    commit("setIsLoading", false);
                    Vue.prototype.$toasted.error('Purchase Number Error!')
                    return;
                }
                invite = invite || "0x0000000000000000000000000000000000000000";
                
                const params = [addr,buyNum,invite];
                console.log("params==",params);
                const res = await web3Wallet.contract("mint").send("mint", params);
                dispatch('getNftBaseInfo');
                commit("setIsLoading", false);
                typeof callback == 'function' && callback()
            }
            catch (err) {
            commit("setIsLoading", false);
            }
          }
        
    },
    getters: {

    }
}