var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout-nft",
    class: _vm.isMobile && 'layout-nft-wap'
  }, [_c('div', {
    staticClass: "layout-nft-content"
  }, [_c('div', {
    staticClass: "nft-header"
  }, [_c('div', {
    staticClass: "nft-header-title",
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6.94002 17.06L19.668 29.78C19.9524 30.045 20.3285 30.1892 20.7171 30.1824C21.1057 30.1755 21.4764 30.0181 21.7513 29.7433C22.0261 29.4685 22.1835 29.0977 22.1904 28.7091C22.1972 28.3205 22.053 27.9444 21.788 27.66L10.122 16L21.788 4.33204C22.053 4.04769 22.1972 3.67159 22.1904 3.28299C22.1835 2.89439 22.0261 2.52362 21.7513 2.24879C21.4764 1.97397 21.1057 1.81654 20.7171 1.80969C20.3285 1.80283 19.9524 1.94708 19.668 2.21204L6.94002 14.94C6.65912 15.2213 6.50134 15.6025 6.50134 16C6.50134 16.3975 6.65912 16.7788 6.94002 17.06Z",
      "fill": "white"
    }
  })]), _c('div', [_vm._v(" " + _vm._s(_vm.$lang('NFT MALL')) + " ")])]), _c('NftMallSortFilter', {
    on: {
      "filter": _vm.filterData
    }
  })], 1), _c('div', {
    staticClass: "nft-content-wapper"
  }, [_vm._l(_vm.recordList, function (item, index) {
    return [_c('NftMallItem', {
      attrs: {
        "nft-item": item,
        "nft-sale-price": _vm.nftSalePrice
      },
      on: {
        "onConfirm": _vm.onItemPurchageClick
      }
    })];
  })], 2), _c('PagerView', {
    attrs: {
      "current-page": _vm.page,
      "total-page": _vm.totalPage
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  }), _c('NftItemPurchaseDialog', {
    attrs: {
      "nft-item": _vm.selectedNftItem,
      "dialog-visible": _vm.showDialog,
      "nft-sale-price": _vm.nftSalePrice
    },
    on: {
      "cancel": _vm.onPurchaseCancel,
      "nftConfirm": _vm.onPurchaseConfirm
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };