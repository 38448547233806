import { createNamespacedHelpers } from "vuex";
import ButtonType1 from "@components/ButtonType1.vue";
import web3Wallet from "@web3Wallet/index";
import BigNumber from "bignumber.js";
import web3Config from "@Web3WalletConfig/config.json";
import PopPrompt from "@components/popPrompt.vue";
import PopPromptTwo from "@components/popPromptTwo.vue";
import StakeRecord from "@components/StakeRecord.vue";
const coinStakeAbi = require("@Web3WalletContracts/coinStake.json");
const walletVuex = createNamespacedHelpers("wallet");
const stakeVuex = createNamespacedHelpers("stake");
export default {
  components: {
    StakeRecord,
    PopPromptTwo,
    PopPrompt,
    ButtonType1
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "isMobile", "connect", "address", "userInfo", "confirmInviteBinding"]),
    ...stakeVuex.mapState(['rectCoinBalance'])
  },
  data() {
    return {
      inputValue: '',
      stakeType: 2,
      calcResult: 0,
      isApprove: false,
      stakeHistory: [],
      unStakeLoading: {},
      popTipShow: false,
      isLoading: false,
      stakeCoinTime: null,
      caclTime: null
    };
  },
  watch: {
    "web3Wallet.address": {
      handler(newVal, oldVal) {
        this.getStakeCoinList();
      },
      deep: true,
      immediate: true
    },
    "inputValue": {
      handler(newVal, oldVal) {
        clearTimeout(this.caclTime);
        if (!this.inputValue) {
          this.calcResult = 0;
          this.isApprove = false;
          return;
        }
        clearTimeout();
        this.caclTime = setTimeout(() => {
          this.onCaclHashRate();
          this.checkApprove();
        }, 300);
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.getRectCoinBalance();
  },
  methods: {
    ...walletVuex.mapActions(["doConnect", "doDisconnect", "changeInviteBindingDialog"]),
    ...stakeVuex.mapActions(["getRectCoinBalance", "getStakeCoinPreGetResult"]),
    switchStakeType(type) {
      this.stakeType = type;
      this.onCaclHashRate();
    },
    async getStakeCoinList() {
      // clearTimeout(this.stakeCoinTime);
      // // if (!web3Wallet.address) return;
      // let params = {
      //   owner: '0xD14dE10ACD68062A2C521d83Ce79DBba7989d7fA'
      // }
      // let rs = await this.$http.post("stake/coin/list", params);
      // vailcode(rs, () => {
      //   this.stakeHistory = rs.data;
      //   this.stakeHistory.map(item => {
      //     item.amount_format = BigNumber(item.amount).div(10 ** web3Config.rect_token_decimals).toFixed(2)
      //   })
      // })
      //
      // this.stakeCoinTime = setTimeout(() => {
      //   this.getStakeCoinList();
      // }, 5 * 1000)
    },
    onCaclHashRate() {
      if (!this.inputValue) return;
      this.getStakeCoinPreGetResult([this.inputValue, this.stakeType, rs => {
        this.calcResult = rs.data;
      }]);
    },
    checkUserInviter() {
      if (!this.confirmInviteBinding && this.userInfo?.user?.must_inviter == 1) {
        this.changeInviteBindingDialog(true);
        return true;
      }
      return false;
    },
    async checkApprove() {
      const params = [web3Wallet.address, coinStakeAbi.address];
      try {
        const res = await web3Wallet.contract("token", web3Config.rect_token_contract).call("allowance", params);
        if (new BigNumber(res).gte(this.inputValue * 10 ** web3Config.rect_token_decimals)) {
          this.isApprove = true;
        } else {
          this.isApprove = false;
        }
      } catch (err) {}
    },
    async doApprove() {
      if (this.checkUserInviter()) {
        return;
      }
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        const price = new BigNumber(this.inputValue).times(10 ** web3Config.rect_token_decimals).toFixed(0);
        const params = [coinStakeAbi.address, price];
        const res = await web3Wallet.contract("token", web3Config.rect_token_contract).send("approve", params);
        this.checkApprove();
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
      }
    },
    async doStake() {
      if (this.checkUserInviter()) {
        return;
      }
      try {
        if (!this.inputValue) {
          this.$toasted.error(this.$lang(`Please Input Stake Amount`));
          return;
        }
        if (parseInt(this.inputValue) < 100) {
          this.$toasted.error(this.$lang(`Minimum stake: 100`));
          return;
        }
        if (this.isLoading) return;
        this.isLoading = true;
        const price = new BigNumber(this.inputValue).times(10 ** web3Config.rect_token_decimals).toFixed(0);
        const params = [price, this.stakeType];
        const res = await web3Wallet.contract("coinStake").send("stake", params);
        this.$toasted.success(this.$lang('Stake submission successful'));
        this.getRectCoinBalance();
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
      }
    },
    async doUnStake(item) {
      try {
        if (this.unStakeLoading[item.id]) return;
        this.unStakeLoading[item.id] = true;
        const params = [item.order_id];
        const res = await web3Wallet.contract("coinStake").send("unStake", params);
        this.$toasted.success(this.$lang('unStake submission successful'));
        this.unStakeLoading[item.id] = false;
      } catch (err) {
        this.unStakeLoading[item.id] = false;
      }
    }
  }
};