import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile"])
  },
  props: {
    inputMin: {
      type: Number,
      default: 1
    },
    inputMax: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      inputButtonLimitMinEnable: false,
      inputButtonLimitMaxEnable: true,
      inputButtonSubEnable: false,
      inputButtonAddEnable: true,
      inputValue: 1
    };
  },
  watch: {
    inputValue: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.checkButtonStatus();
          this.$emit('updateInputValue', newVal);
        }
      },
      immediate: true
    },
    inputMax: {
      handler(newVal, oldVal) {
        this.checkButtonStatus();
        this.$emit('updateInputValue', newVal);
      },
      immediate: true
    },
    inputMin: {
      handler(newVal, oldVal) {
        // this.checkButtonStatus()
        //
        // this.$emit('updateInputValue', newVal)
      },
      immediate: true
    }
  },
  methods: {
    checkAndNofity() {
      console.log('checkAndNofity');
      this.checkButtonStatus();
      this.$emit('updateInputValue', this.inputValue);
    },
    checkButtonStatus() {
      if (this.inputValue <= this.inputMin) {
        this.inputButtonLimitMinEnable = false;
        this.inputButtonSubEnable = false;
        this.inputValue = this.inputMin;
      } else {
        this.inputButtonLimitMinEnable = true;
        this.inputButtonSubEnable = true;
      }
      if (this.inputValue >= this.inputMax) {
        this.inputButtonLimitMaxEnable = false;
        this.inputButtonAddEnable = false;
        this.inputValue = this.inputMax;
      } else {
        this.inputButtonLimitMaxEnable = true;
        this.inputButtonAddEnable = true;
      }
    },
    onClickSub() {
      if (this.inputValue > this.inputMin) {
        this.inputValue = this.inputValue - 1;
      } else {
        this.inputValue = this.inputMin;
      }
      this.checkButtonStatus();
    },
    onClickAdd() {
      if (this.inputValue < this.inputMax) {
        this.inputValue = this.inputValue + 1;
      } else {
        this.inputValue = this.inputMax;
      }
      this.checkButtonStatus();
    },
    onClickMin() {
      this.inputValue = this.inputMin;
      this.checkButtonStatus();
    },
    onClickMax() {
      this.inputValue = this.inputMax;
      this.checkButtonStatus();
    }
  }
};