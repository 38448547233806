var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CommonTipsDialog', {
    attrs: {
      "custom-slot-visible": _vm.dialogVisible,
      "has-actions": true,
      "title": _vm.$lang('Transfer')
    },
    on: {
      "cancel": function ($event) {
        return _vm.$emit('cancel');
      },
      "confirm": _vm.onConfirm
    },
    scopedSlots: _vm._u([{
      key: "custom-tips",
      fn: function () {
        return [_c('div', {
          staticClass: "layout-profile-dialog",
          class: _vm.isMobile && 'layout-profile-dialog-wap'
        }, [_c('div', {
          staticClass: "layout-assets"
        }, [_c('div', {
          staticClass: "layout-profile-title-1"
        }, [_vm._v(" " + _vm._s(_vm.$lang('Select assets')) + " ")]), _c('div', {
          staticClass: "layout-assets-choose"
        }, [_c('div', {
          staticClass: "layout-assets-choose-title"
        }, [_vm._v(" RECT ")]), _c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "16",
            "height": "17",
            "viewBox": "0 0 16 17",
            "fill": "none"
          }
        }, [_c('path', {
          attrs: {
            "d": "M8.53002 13.0297L14.89 6.66568C15.0225 6.52351 15.0946 6.33546 15.0912 6.14116C15.0878 5.94686 15.0091 5.76147 14.8716 5.62406C14.7342 5.48665 14.5488 5.40794 14.3545 5.40451C14.1602 5.40108 13.9722 5.4732 13.83 5.60568L8.00002 11.4387L2.16602 5.60568C2.02384 5.4732 1.8358 5.40108 1.6415 5.40451C1.44719 5.40793 1.26181 5.48665 1.1244 5.62406C0.986984 5.76147 0.908272 5.94686 0.904844 6.14116C0.901415 6.33546 0.973539 6.52351 1.10602 6.66568L7.47002 13.0297C7.61064 13.1701 7.80127 13.249 8.00002 13.249C8.19877 13.249 8.38939 13.1701 8.53002 13.0297Z",
            "fill": "white"
          }
        })])])]), _c('div', {
          staticClass: "layout-from-to"
        }, [_c('div', {
          staticClass: "layout-from"
        }, [_c('div', {
          staticClass: "layout-profile-title-1"
        }, [_vm._v(" " + _vm._s(_vm.$lang('From')) + " ")]), _c('div', {
          staticClass: "layout-from-to-select",
          on: {
            "click": function ($event) {
              _vm.showSelectedFrom = true;
            }
          }
        }, [_vm.fromType == 'amount' ? _c('div', {
          staticClass: "layout-profile-title-1"
        }, [_vm._v(" " + _vm._s(_vm.$lang('Profit Account')) + " ")]) : _vm.fromType == 'rect_amount' ? _c('div', {
          staticClass: "layout-profile-title-1"
        }, [_vm._v(" " + _vm._s(_vm.$lang('Center Account')) + " ")]) : _c('div', {
          staticClass: "layout-profile-title-1"
        }, [_vm._v(" " + _vm._s(_vm.$lang('Select account')) + " ")]), _c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "16",
            "height": "17",
            "viewBox": "0 0 16 17",
            "fill": "none"
          }
        }, [_c('path', {
          attrs: {
            "d": "M8.53002 13.0297L14.89 6.66568C15.0225 6.52351 15.0946 6.33546 15.0912 6.14116C15.0878 5.94686 15.0091 5.76147 14.8716 5.62406C14.7342 5.48665 14.5488 5.40794 14.3545 5.40451C14.1602 5.40108 13.9722 5.4732 13.83 5.60568L8.00002 11.4387L2.16602 5.60568C2.02384 5.4732 1.8358 5.40108 1.6415 5.40451C1.44719 5.40793 1.26181 5.48665 1.1244 5.62406C0.986984 5.76147 0.908272 5.94686 0.904844 6.14116C0.901415 6.33546 0.973539 6.52351 1.10602 6.66568L7.47002 13.0297C7.61064 13.1701 7.80127 13.249 8.00002 13.249C8.19877 13.249 8.38939 13.1701 8.53002 13.0297Z",
            "fill": "white"
          }
        })])]), _vm.showSelectedFrom ? _c('div', {
          staticClass: "filter-popup-selected"
        }, [_c('div', {
          staticClass: "filter-popup-selected-item",
          on: {
            "click": function ($event) {
              return _vm.selectedFromType('amount');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$lang('Profit Account')) + " ")]), _c('div', {
          staticClass: "filter-popup-selected-item",
          on: {
            "click": function ($event) {
              return _vm.selectedFromType('rect_amount');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$lang('Center Account')) + " ")])]) : _vm._e()]), _c('div', {
          staticClass: "layout-from-to-center"
        }, [_c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "16",
            "height": "20",
            "viewBox": "0 0 16 20",
            "fill": "none"
          }
        }, [_c('path', {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            "d": "M7.12419 0.589979L15.6095 9.05917C16.1302 9.57888 16.1302 10.4215 15.6095 10.9412L7.12419 19.4104C6.6035 19.9301 5.75928 19.9301 5.23858 19.4104C4.71788 18.8907 4.71788 18.0481 5.23858 17.5284L11.4477 11.331L0 11.331L0 8.66939L11.4477 8.66939L5.23858 2.47202C4.71788 1.95231 4.71788 1.10969 5.23858 0.589979C5.75928 0.0702673 6.6035 0.0702673 7.12419 0.589979Z",
            "fill": "white"
          }
        })])]), _c('div', {
          staticClass: "layout-from"
        }, [_c('div', {
          staticClass: "layout-profile-title-1"
        }, [_vm._v(" " + _vm._s(_vm.$lang('To')) + " ")]), _c('div', {
          staticClass: "layout-from-to-select",
          on: {
            "click": function ($event) {
              _vm.showSelectedTo = true;
            }
          }
        }, [_vm.toType == 'amount' ? _c('div', {
          staticClass: "layout-profile-title-1"
        }, [_vm._v(" " + _vm._s(_vm.$lang('Profit Account')) + " ")]) : _vm.toType == 'rect_amount' ? _c('div', {
          staticClass: "layout-profile-title-1"
        }, [_vm._v(" " + _vm._s(_vm.$lang('Center Account')) + " ")]) : _c('div', {
          staticClass: "layout-profile-title-1"
        }, [_vm._v(" " + _vm._s(_vm.$lang('Select account')) + " ")]), _c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "16",
            "height": "17",
            "viewBox": "0 0 16 17",
            "fill": "none"
          }
        }, [_c('path', {
          attrs: {
            "d": "M8.53002 13.0297L14.89 6.66568C15.0225 6.52351 15.0946 6.33546 15.0912 6.14116C15.0878 5.94686 15.0091 5.76147 14.8716 5.62406C14.7342 5.48665 14.5488 5.40794 14.3545 5.40451C14.1602 5.40108 13.9722 5.4732 13.83 5.60568L8.00002 11.4387L2.16602 5.60568C2.02384 5.4732 1.8358 5.40108 1.6415 5.40451C1.44719 5.40793 1.26181 5.48665 1.1244 5.62406C0.986984 5.76147 0.908272 5.94686 0.904844 6.14116C0.901415 6.33546 0.973539 6.52351 1.10602 6.66568L7.47002 13.0297C7.61064 13.1701 7.80127 13.249 8.00002 13.249C8.19877 13.249 8.38939 13.1701 8.53002 13.0297Z",
            "fill": "white"
          }
        })])]), _vm.showSelectedTo ? _c('div', {
          staticClass: "filter-popup-selected"
        }, [_c('div', {
          staticClass: "filter-popup-selected-item",
          on: {
            "click": function ($event) {
              return _vm.selectedToType('amount');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$lang('Profit Account')) + " ")]), _c('div', {
          staticClass: "filter-popup-selected-item",
          on: {
            "click": function ($event) {
              return _vm.selectedToType('rect_amount');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$lang('Center Account')) + " ")])]) : _vm._e()])]), _c('div', {
          staticClass: "layout-amount"
        }, [_c('div', {
          staticClass: "layout-profile-title-1"
        }, [_vm._v(" " + _vm._s(_vm.$lang('amount')) + " ")]), _c('div', {
          staticClass: "layout-amount-wrapper"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.inputAmount,
            expression: "inputAmount"
          }],
          attrs: {
            "placeholder": _vm.$lang('Enter  Amount'),
            "type": "number"
          },
          domProps: {
            "value": _vm.inputAmount
          },
          on: {
            "focusout": _vm.checkInputAmountLimit,
            "input": function ($event) {
              if ($event.target.composing) return;
              _vm.inputAmount = $event.target.value;
            }
          }
        }), _c('div', {
          staticClass: "layout-amount-wrapper-button",
          on: {
            "click": function ($event) {
              _vm.inputAmount = _vm.accountBalance;
            }
          }
        }, [_vm._v(_vm._s(_vm.$lang('Max')))])]), _c('div', {
          staticClass: "layout-amount-balance"
        }, [_c('div', [_vm._v(" " + _vm._s(_vm.$lang('available balance')) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.accountBalance) + " RECT ")])])])])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };