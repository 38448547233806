import { createNamespacedHelpers } from "vuex";
import { showCommonTipsDialog, showCommonTipsErrorDialog } from "@components/CommonDialog";
import web3Wallet from "@web3Wallet";
import Vue from "vue";
import web3Config from "@Web3WalletConfig/config.json";
import { formatRectCoinAmount } from "@utils";
import { BaseError } from "viem";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  props: {
    dialogVisible: Boolean
  },
  data() {
    return {
      inputAmount: 0,
      accountBalance: 0
    };
  },
  watch: {
    dialogVisible: {
      handler(newValue, oldValue) {
        this.getAccountBalance();
      }
    }
  },
  mounted() {},
  methods: {
    onConfirm() {
      if (this.inputAmount <= 0 || this.inputAmount > this.accountBalance) {
        showCommonTipsErrorDialog(this.$lang('hint'), this.$lang('Please input correct amount'));
        return;
      }
      this.$loading.show();
      //sign message
      let message = 'withdraw amount action';
      web3Wallet.signMessage(message).then(signed => {
        let params = {
          owner: this.address,
          amount: this.inputAmount,
          assets_type: 'rect_amount',
          signed: signed,
          signedMsg: 'withdraw amount action'
        };
        return Vue.prototype.$http.post("staker/withdraw", params);
      }).then(data => {
        if (data.code != 0) {
          showCommonTipsErrorDialog(this.$lang('hint'), data.message);
          throw new Error(data.message);
        }
        data = data.data;
        console.log(data);
        // "token": "0xc5775611042682F5E734543067602E7749423b65",
        //     "orderId": "20241207065228656142",
        //     "timestamp": 1733554348,
        //     "withdrawAmount": "1000000000000000000",
        //     "seeds": [
        //   "0x60b9178b552e4a2ed93f9022b5cea5778a3c7c326b6b3dfe2ac02c97c5ae47d3",
        //   "0x75ee5f5fda5d9b37432a9c47a6aa5042d5be6e3dd223dc83737b3c91980a17b3",
        //   27
        // ]
        //请求合约
        const params = [data.token, data.orderId, data.withdrawAmount, data.timestamp, data.seeds];
        return web3Wallet.contract("rechargeAndWithdrawContract", web3Config.recharge_withdraw_contract).send("withdraw", params);
      }).then(data => {
        // console.log(data)

        this.$loading.hide();
        return showCommonTipsDialog(this.$lang('success'), this.$lang('Withdraw successful'));
      }).then(() => {
        this.$emit('confirm');
      }).catch(e => {
        console.error('recharge error', e);
        if (e instanceof BaseError) {
          //合约错误
          showCommonTipsErrorDialog(this.$lang('hint'), e.shortMessage);
        }
      }).finally(() => {
        this.$loading.hide();
      });
    },
    checkInputAmountLimit() {
      console.log('check limit', this.inputAmount, this.accountBalance);
      if (this.inputAmount <= 0) {
        this.inputAmount = 0;
      } else {
        let inputAmount = this.accountBalance;
        if (this.inputAmount > inputAmount) {
          if (inputAmount <= 0) inputAmount = 0;
          this.inputAmount = inputAmount;
        }
      }
    },
    getAccountBalance() {
      this.$loading.show();
      let params = {
        "owner": this.address
      };
      Vue.prototype.$http.post("staker/info", params).then(data => {
        data = data.data;
        this.accountBalance = formatRectCoinAmount(data.rect_amount);
      }).catch(() => {}).finally(() => {
        this.$loading.hide();
      });
    }
  }
};