import { createNamespacedHelpers } from "vuex";
import { formatDefaultNumber } from "../utils";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  data() {
    return {
      recordList: [],
      page: 1,
      totalPage: 1,
      showFilter: false,
      filterData: [],
      filerType: '',
      filterTypeValue: ''
    };
  },
  watch: {
    address: {
      handler(newVal, oldVal) {},
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.loadFilterData();
  },
  methods: {
    formatDefaultNumber,
    onPageChange(page) {
      console.log(page);
      this.page = page;
      this.onLoadData(page);
    },
    hideFilterMenu(type) {
      this.showFilter = false;
      this.filerType = type.idx;
      this.filterTypeValue = type.distribution_date;
      this.page = 1;
      this.onLoadData();
    },
    loadFilterData() {
      this.$http.post('/pool/distribution', {
        pool_type: 'A'
      }).then(data => {
        console.log(data);
        this.filterData = data.data;
        this.filerType = data.data[0].idx;
        this.filterTypeValue = data.data[0].distribution_date;
        this.page = 1;
        this.onLoadData();
      }).catch(() => {});
    },
    onLoadData() {
      this.$loading.show();
      let params = {
        page: this.page,
        pageSize: 10,
        pool_type: 'A',
        distribution_date: this.filterTypeValue,
        orderColumn: 'rank',
        orderDirection: 'asc'
      };
      this.$http.post("pool/logs", params).then(data => {
        this.recordList = data.data.list;
        this.totalPage = data.data.totalPage;
      }).catch(err => {}).finally(() => {
        this.$loading.hide();
      });
    }
  }
};