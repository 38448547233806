import BigNumber from "bignumber.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "connect", "address", "userInfo"])
  },
  data() {
    return {
      showRecordDialog: false,
      overviewData: {}
    };
  },
  methods: {
    async showChangeRecordDialog() {
      if (this.userInfo == null) {
        return;
      }
      this.showRecordDialog = true;
      let rs = await this.$http.post("/stake/overview", {
        owner: this.address
      });
      // let rs = await this.$http.post("https://api.reflectionai.app/stake/overview?owner=0x1A22ab8E7C36645eb44d5C6aB056f4A27AEB755c")
      if (rs.code == 0) {
        let overviewData = rs.data.rs;
        overviewData.total_nodes_hashrate = BigNumber(overviewData.total_nodes_hashrate).decimalPlaces(0, BigNumber.ROUND_DOWN);
        overviewData.total_active_hashrate = BigNumber(overviewData.total_active_hashrate).decimalPlaces(0, BigNumber.ROUND_DOWN);
        overviewData.total_active_amount = BigNumber(overviewData.total_active_amount).div(10 ** 18).decimalPlaces(0, BigNumber.ROUND_DOWN);
        overviewData.total_fixed_hashrate = BigNumber(overviewData.total_fixed_hashrate).decimalPlaces(0, BigNumber.ROUND_DOWN);
        overviewData.total_fixed_amount = BigNumber(overviewData.total_fixed_amount).div(10 ** 18).decimalPlaces(0, BigNumber.ROUND_DOWN);
        overviewData.total_burn_hashrate = BigNumber(overviewData.total_burn_hashrate).decimalPlaces(0, BigNumber.ROUND_DOWN);
        overviewData.total_burn_amount = BigNumber(overviewData.total_burn_amount).div(10 ** 18).decimalPlaces(0, BigNumber.ROUND_DOWN);
        this.overviewData = overviewData;
      }
    },
    changeRecordDialog() {
      this.showRecordDialog = false;
    }
  }
};