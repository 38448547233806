import { createNamespacedHelpers } from "vuex";
import InputNumCalViewNftPurchase from "@components/InputNumCalViewNftPurchase.vue";
import { formatRectCoinAmount } from "@utils";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    InputNumCalViewNftPurchase
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  props: {
    dialogVisible: Boolean,
    nftItem: {
      type: Object,
      default: {}
    },
    nftSalePrice: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      titleFormat: '',
      inputNumber: 0,
      accountRectBalance: 0
    };
  },
  watch: {
    dialogVisible: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.getAccountBalance();
        }
      }
    }
  },
  mounted() {
    this.titleTips();
  },
  methods: {
    titleTips() {
      // $lang('Purchase <span>%s</span> first-generation NFTs') | sprintf
      let tips = this.$options.filters['sprintf'](this.$lang('Purchase <span>%s</span> first-generation NFTs'), this.inputNumber);
      this.titleFormat = tips;
    },
    updateInputNumber(input) {
      this.inputNumber = input;
      this.titleTips();
    },
    onConfirm() {
      this.$emit('nftConfirm', this.inputNumber);
    },
    getAccountBalance() {
      this.$loading.show();
      let params = {
        "owner": this.address
      };
      this.$http.post("staker/info", params).then(data => {
        data = data.data;
        this.accountRectBalance = formatRectCoinAmount(data.rect_amount);
      }).catch(() => {}).finally(() => {
        this.$loading.hide();
      });
    }
  }
};