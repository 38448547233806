var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-center",
    class: _vm.isMobile && 'wap-content-center'
  }, [_c('div', {
    staticClass: "stake-box-left"
  }, [_c('img', {
    attrs: {
      "src": require('@images/center-box-image-3.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "stake-box-right"
  }, [_c('div', {
    staticClass: "stake-box-right-tile-line"
  }, [_c('div', {
    staticClass: "stake-box-title"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Transfer')) + " ")]), _c('div', {
    staticClass: "stake-box-sub"
  }, [_vm._v(" " + _vm._s(_vm.$lang('available')) + ": " + _vm._s(_vm.nftAvailableNum) + " ")])]), _c('div', {
    staticClass: "hor-line"
  }), _c('div', {
    staticClass: "stake-box-sub m-t-20"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Receiver wallet address')) + " ")]), _c('div', {
    staticClass: "input-address"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputWalletAddr,
      expression: "inputWalletAddr"
    }],
    attrs: {
      "placeholder": _vm.$lang('Receiver wallet address')
    },
    domProps: {
      "value": _vm.inputWalletAddr
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.inputWalletAddr = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "stake-input-line"
  }, [_c('InputNumCalView', {
    attrs: {
      "input-min": 1,
      "input-max": _vm.nftAvailableNum
    },
    on: {
      "updateInputValue": _vm.updateInputNumber
    }
  }), _c('div', {
    staticClass: "stake-button",
    on: {
      "click": _vm.confirmTransfer
    }
  }, [_vm._v(_vm._s(_vm.$lang('Confirm')))])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };