var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout-center-rewards",
    class: _vm.isMobile && 'wap-layout-center-rewards'
  }, [_c('div', {
    staticClass: "layout-reward-item"
  }, [_c('div', {
    staticClass: "layout-reward-item-icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/icon-reward-1.png')
    }
  })]), _c('div', {
    staticClass: "layout-reward-item-right"
  }, [_c('div', {
    staticClass: "layout-reward-item-right-title"
  }, [_vm._v(" " + _vm._s(_vm.reward1) + " RECT ")]), _c('div', {
    staticClass: "layout-reward-item-right-bottom"
  }, [_c('div', {
    staticClass: "layout-reward-item-right-sub"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Builder Rewards')) + " ")]), _c('div', {
    staticClass: "layout-reward-item-right-button",
    on: {
      "click": function ($event) {
        return _vm.showRecord(1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Record')) + " ")])])])]), _c('div', {
    staticClass: "layout-reward-item layout-reward-item-bg2"
  }, [_c('div', {
    staticClass: "layout-reward-item-icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/icon-reward-2.png')
    }
  })]), _c('div', {
    staticClass: "layout-reward-item-right"
  }, [_c('div', {
    staticClass: "layout-reward-item-right-title"
  }, [_vm._v(" " + _vm._s(_vm.reward2) + " RECT ")]), _c('div', {
    staticClass: "layout-reward-item-right-bottom"
  }, [_c('div', {
    staticClass: "layout-reward-item-right-sub"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Contributor rewards')) + " ")]), _c('div', {
    staticClass: "layout-reward-item-right-button",
    on: {
      "click": function ($event) {
        return _vm.showRecord(2);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Record')) + " ")])])])]), _c('div', {
    staticClass: "layout-reward-item layout-reward-item-bg3"
  }, [_c('div', {
    staticClass: "layout-reward-item-icon"
  }, [_c('img', {
    attrs: {
      "src": require('@images/icon-reward-3.png')
    }
  })]), _c('div', {
    staticClass: "layout-reward-item-right"
  }, [_c('div', {
    staticClass: "layout-reward-item-right-title"
  }, [_vm._v(" " + _vm._s(_vm.reward3) + " RECT ")]), _c('div', {
    staticClass: "layout-reward-item-right-bottom"
  }, [_c('div', {
    staticClass: "layout-reward-item-right-sub"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Burn blind box')) + " ")]), _c('div', {
    staticClass: "layout-reward-item-right-button",
    on: {
      "click": function ($event) {
        return _vm.showRecord(3);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Record')) + " ")])])])]), _c('LayoutCenterRewardRecord', {
    attrs: {
      "show-record-dialog": _vm.showRecordDialog,
      "record-type": _vm.recordDialogType
    },
    on: {
      "closeDialog": function ($event) {
        _vm.showRecordDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };