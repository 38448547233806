import { createNamespacedHelpers } from "vuex";
import InputNumCalView from "@components/InputNumCalView.vue";
import { showCommonTipsDialog } from "@components/CommonDialog";
import Vue from "vue";
import web3Wallet from "@web3Wallet";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    InputNumCalView
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "address"])
  },
  props: {
    nftAvailableNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      inputNumber: 1
    };
  },
  methods: {
    updateInputNumber(value) {
      this.inputNumber = value;
    },
    confirmStake() {
      if (this.nftAvailableNum <= 0 || this.inputNumber > this.nftAvailableNum) {
        showCommonTipsDialog(this.$lang('hint'), this.$lang('Owning an Insufficient Number of Generation NFTs')).then(() => {}).catch(() => {});
        return;
      }
      this.$loading.show();
      let params = {
        amount: parseInt(this.inputNumber) * 100,
        stake_type: 1
      };
      Vue.prototype.$http.post("stake/calc/coin/hashrate", params).then(data => {
        this.$loading.hide();
        let tips = this.$options.filters['sprintf'](this.$lang('The current pledge is expected to obtain <span>%s</span> compute'), data.data);
        return showCommonTipsDialog(this.$lang('Stake'), tips, true);
      }).then(() => {
        this.$loading.show();
        //sign message
        let message = 'stake nft action';
        return web3Wallet.signMessage(message);
      }).then(signed => {
        let params = {
          owner: this.address,
          count: this.inputNumber,
          signed: signed,
          signedMsg: 'stake nft action'
        };
        return Vue.prototype.$http.post("nft/stake", params);
      }).then(data => {
        this.$loading.hide();
        if (data.code == 0) {
          this.$emit('stakeSuccess');
          return showCommonTipsDialog(this.$lang('success'), this.$lang('Stake successful !!'));
        } else {
          return showCommonTipsDialog(this.$lang('hint'), data.message);
        }
      }).then(() => {}).catch(() => {}).finally(() => {
        this.$loading.hide();
      });
    }
  }
};