var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout-ranking-jianshe",
    class: _vm.isMobile && 'layout-ranking-jianshe-wap'
  }, [_c('div', {
    staticClass: "layout-ranking-jianshe-title-line"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.$lang('奉献者排名')) + "-" + _vm._s(_vm.filerType) + " ")]), _c('div', {
    staticClass: "layout-ranking-jianshe-title-filter",
    on: {
      "click": function ($event) {
        _vm.showFilter = !_vm.showFilter;
      }
    }
  }, [_c('div', [_vm._v(_vm._s(_vm._f("sprintf")(_vm.$lang('第 %s 期'), _vm.filerType)))]), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5.33132 7.83117L9.30632 3.85367C9.38912 3.76481 9.4342 3.64729 9.43206 3.52585C9.42991 3.40441 9.38072 3.28854 9.29484 3.20266C9.20895 3.11678 9.09309 3.06758 8.97165 3.06544C8.85021 3.0633 8.73268 3.10837 8.64382 3.19117L5.00007 6.8368L1.35382 3.19117C1.26496 3.10837 1.14743 3.0633 1.026 3.06544C0.904558 3.06758 0.788692 3.11678 0.702809 3.20266C0.616926 3.28854 0.56773 3.40441 0.565588 3.52585C0.563445 3.64729 0.608522 3.76481 0.691322 3.85367L4.66882 7.83117C4.75671 7.91896 4.87585 7.96826 5.00007 7.96826C5.12429 7.96826 5.24343 7.91896 5.33132 7.83117Z",
      "fill": "white"
    }
  })])]), _vm.showFilter ? _c('div', {
    staticClass: "filter-popup"
  }, _vm._l(_vm.filterData, function (item, index) {
    return _c('div', {
      staticClass: "filter-popup-item",
      on: {
        "click": function ($event) {
          return _vm.hideFilterMenu(item);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm._f("sprintf")(_vm.$lang('第 %s 期'), item.idx)) + " ")]);
  }), 0) : _vm._e()]), _c('div', {
    staticClass: "layout-ranking-jianshe-table"
  }, [_c('div', {
    staticClass: "table-title-line"
  }, [_c('div', {
    staticClass: "col-1"
  }, [_vm._v(_vm._s(_vm.$lang('排名')))]), _c('div', {
    staticClass: "col-2"
  }, [_vm._v(_vm._s(_vm.$lang('新增排行算力')))]), _c('div', {
    staticClass: "col-3"
  }, [_vm._v(_vm._s(_vm.$lang('地址')))])]), _c('div', {
    staticClass: "table-item-content"
  }, _vm._l(_vm.recordList, function (item, index) {
    return _c('div', {
      staticClass: "table-item-line"
    }, [_c('div', {
      staticClass: "col-1 title-1 ranking-line-1"
    }, [item.rank == 1 ? _c('img', {
      attrs: {
        "src": require('@images/icon-ranking-1.png')
      }
    }) : item.rank == 2 ? _c('img', {
      attrs: {
        "src": require('@images/icon-ranking-2.png')
      }
    }) : item.rank == 3 ? _c('img', {
      attrs: {
        "src": require('@images/icon-ranking-3.png')
      }
    }) : _vm._e(), _c('div', [_vm._v(" " + _vm._s(_vm._f("sprintf")(_vm.$lang('第 %s 名'), item.rank)))])]), _c('div', {
      staticClass: "col-2 title-2"
    }, [_vm._v(_vm._s(_vm.formatDefaultNumber(item.hashrate)))]), _c('div', {
      staticClass: "col-3 title-2"
    }, [_vm._v(_vm._s(_vm._f("fmt_address")(item.staker, 12)))])]);
  }), 0), _c('div', {
    staticClass: "layout-ranking-pager"
  }, [_c('PagerView', {
    attrs: {
      "current-page": _vm.page,
      "total-page": _vm.totalPage
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  })], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };