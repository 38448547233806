var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-center",
    class: _vm.isMobile && 'wap-content-center'
  }, [_c('div', {
    staticClass: "stake-box-left"
  }, [_c('img', {
    attrs: {
      "src": require('@images/center-box-image-1.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "stake-box-right"
  }, [_c('div', {
    staticClass: "stake-box-right-tile-line"
  }, [_c('div', {
    staticClass: "stake-box-title"
  }, [_vm._v(" " + _vm._s(_vm.$lang('Stake')) + " ")]), _c('div', {
    staticClass: "stake-box-sub"
  }, [_vm._v(" " + _vm._s(_vm.$lang('available')) + ": " + _vm._s(_vm.nftAvailableNum) + " ")])]), _c('div', {
    staticClass: "hor-line"
  }), _c('div', {
    ref: "refInputLine",
    staticClass: "stake-input-line"
  }, [_c('InputNumCalView', {
    attrs: {
      "input-min": 1,
      "input-max": _vm.nftAvailableNum
    },
    on: {
      "updateInputValue": _vm.updateInputNumber
    }
  }), _c('div', {
    staticClass: "stake-button",
    on: {
      "click": _vm.confirmStake
    }
  }, [_vm._v(_vm._s(_vm.$lang('Confirm')))])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };