import { createNamespacedHelpers } from "vuex";
import web3Wallet from "@web3Wallet";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...walletVuex.mapState(["showInviteBindingDialog", "isMobile", "theme", "connect", "address"])
  },
  data() {
    return {
      inviterAddr: '',
      popInviterTipShow: false,
      popTipContent: ''
    };
  },
  watch: {
    address: {
      async handler(newVal, oldVal) {
        this.popInviterTipShow = false;
      },
      immediate: true,
      deep: true
    }
  },
  async mounted() {
    setTimeout(() => {
      this.inviterAddr = cookie.get("t") || localStorage.getItem('t') || '';
    }, 300);
  },
  methods: {
    ...walletVuex.mapActions(["doConnect", "refreshUserInfo", "changeInviteBindingDialog", "inviteBindingConfrim"]),
    hideDialog() {
      this.changeInviteBindingDialog(false);
      this.inviteBindingConfrim(true);
    },
    async bindInviter(type) {
      try {
        let inviteAddr = this.inviterAddr;
        if (type == 1) {
          if (!this.inviterAddr || this.inviterAddr == "0x0000000000000000000000000000000000000000") {
            this.popTipContent = this.$lang('Enter Your Reference Address');
            this.popInviterTipShow = true;
            return false;
          }
        } else {
          inviteAddr = '';
        }
        let signedMsg = "binding";
        let signed = await web3Wallet.signMessage(signedMsg);
        let params = {
          owner: this.address,
          inviter: inviteAddr,
          signedMsg: signedMsg,
          signed: signed
        };
        let rs = await this.$http.post("stake/bind/inviter", params);
        vailcode(rs, async () => {
          await this.refreshUserInfo();
          this.hideDialog();
        });
      } catch (e) {
        console.log(e);
        this.$http.post("/appcatch", {
          'action': "binding",
          address: this.address,
          eee: e
        });
        this.hideDialog();
      }
    }
  }
};