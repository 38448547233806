var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CommonTipsDialog', {
    attrs: {
      "custom-slot-visible": _vm.dialogVisible
    },
    on: {
      "confirm": function ($event) {
        return _vm.$emit('cancel');
      }
    },
    scopedSlots: _vm._u([{
      key: "custom-title",
      fn: function () {
        return [_c('div', {
          staticClass: "nft-record-top-line"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v(" " + _vm._s(_vm.$lang('NFT record')) + " ")]), _c('div', {
          staticClass: "filter-button",
          on: {
            "click": _vm.showFilterMenu
          }
        }, [_c('div', [_vm._v(" " + _vm._s(_vm.$lang('All')) + " ")]), _c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "10",
            "height": "11",
            "viewBox": "0 0 10 11",
            "fill": "none"
          }
        }, [_c('path', {
          attrs: {
            "d": "M5.33132 8.33117L9.30632 4.35367C9.38912 4.26481 9.4342 4.14729 9.43206 4.02585C9.42991 3.90441 9.38072 3.78854 9.29484 3.70266C9.20895 3.61678 9.09309 3.56758 8.97165 3.56544C8.85021 3.5633 8.73268 3.60837 8.64382 3.69117L5.00007 7.3368L1.35382 3.69117C1.26496 3.60837 1.14743 3.5633 1.026 3.56544C0.904558 3.56758 0.788692 3.61678 0.702809 3.70266C0.616926 3.78854 0.56773 3.90441 0.565588 4.02585C0.563445 4.14729 0.608522 4.26481 0.691322 4.35367L4.66882 8.33117C4.75671 8.41896 4.87585 8.46826 5.00007 8.46826C5.12429 8.46826 5.24343 8.41896 5.33132 8.33117Z",
            "fill": "white"
          }
        })])]), _vm.showFilter ? _c('div', {
          staticClass: "filter-popup"
        }, [_c('div', {
          staticClass: "filter-popup-item",
          on: {
            "click": function ($event) {
              return _vm.hideFilterMenu('');
            }
          }
        }, [_vm._v(_vm._s(_vm.$lang('All')))]), _c('div', {
          staticClass: "filter-popup-item",
          on: {
            "click": function ($event) {
              return _vm.hideFilterMenu('map');
            }
          }
        }, [_vm._v(_vm._s(_vm.$lang('Mapping')))]), _c('div', {
          staticClass: "filter-popup-item",
          on: {
            "click": function ($event) {
              return _vm.hideFilterMenu('stake');
            }
          }
        }, [_vm._v(_vm._s(_vm.$lang('stake')))]), _c('div', {
          staticClass: "filter-popup-item",
          on: {
            "click": function ($event) {
              return _vm.hideFilterMenu('transferIn');
            }
          }
        }, [_vm._v(_vm._s(_vm.$lang('transferIn')))]), _c('div', {
          staticClass: "filter-popup-item",
          on: {
            "click": function ($event) {
              return _vm.hideFilterMenu('transferOut');
            }
          }
        }, [_vm._v(_vm._s(_vm.$lang('transferOut')))]), _c('div', {
          staticClass: "filter-popup-item",
          on: {
            "click": function ($event) {
              return _vm.hideFilterMenu('sale');
            }
          }
        }, [_vm._v(_vm._s(_vm.$lang('sale')))]), _c('div', {
          staticClass: "filter-popup-item",
          on: {
            "click": function ($event) {
              return _vm.hideFilterMenu('unsale');
            }
          }
        }, [_vm._v(_vm._s(_vm.$lang('unsale')))]), _c('div', {
          staticClass: "filter-popup-item",
          on: {
            "click": function ($event) {
              return _vm.hideFilterMenu('sell');
            }
          }
        }, [_vm._v(_vm._s(_vm.$lang('sell')))]), _c('div', {
          staticClass: "filter-popup-item",
          on: {
            "click": function ($event) {
              return _vm.hideFilterMenu('buy');
            }
          }
        }, [_vm._v(_vm._s(_vm.$lang('buy')))])]) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "custom-tips",
      fn: function () {
        return [_c('div', {
          staticClass: "nft-record-content"
        }, [_c('div', {
          staticClass: "tab-header"
        }, [_c('div', {
          staticClass: "col-1"
        }, [_vm._v(_vm._s(_vm.$lang('time')))]), _c('div', {
          staticClass: "col-2"
        }, [_vm._v(_vm._s(_vm.$lang('type')))]), _c('div', {
          staticClass: "col-3"
        }, [_vm._v(_vm._s(_vm.$lang('value')))])]), _c('div', {
          ref: "myScroll",
          staticClass: "tab-content",
          on: {
            "scroll": _vm.checkScroll
          }
        }, [!_vm.recordList || _vm.recordList.length <= 0 ? [!_vm.logLoading ? _c('EmptyLayout') : _vm._e()] : _vm._e(), _vm._l(_vm.recordList, function (item, index) {
          return _c('div', {
            staticClass: "tab-content-item"
          }, [_c('div', {
            staticClass: "col-1 tab-item-title-1"
          }, [_vm._v(_vm._s(item.showDateFormat))]), _c('div', {
            staticClass: "col-2 tab-item-title-2"
          }, [_vm._v(_vm._s(_vm.actionName(item.action)))]), _c('div', {
            staticClass: "col-3 tab-item-title-2"
          }, [_vm._v(_vm._s(item.nft_num))])]);
        }), _vm.logLoading ? _c('div', {
          staticClass: "loadingList"
        }, [_c('div', {
          staticClass: "loading-list"
        }, _vm._l(3, function (_) {
          return _c('div');
        }), 0)]) : _vm._e()], 2)])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };