import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  props: {
    totalPage: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      splitPage: []
    };
  },
  watch: {
    currentPage: {
      handler(newValue, oldValue) {
        this.calSplitPage();
      }
    },
    totalPage: {
      handler(newValue, oldValue) {
        this.calSplitPage();
      }
    }
  },
  mounted() {
    this.calSplitPage();
  },
  methods: {
    calSplitPage() {
      if (this.totalPage <= 10) {
        return;
      }
      let currentPage = this.currentPage;
      if (currentPage < 4) {
        this.splitPage = [[1, 2, 3, 4, 5], [-1], [this.totalPage - 2, this.totalPage - 1, this.totalPage]];
      } else if (currentPage > this.totalPage - 4) {
        this.splitPage = [[1, 2, 3], [-1], [this.totalPage - 4, this.totalPage - 3, this.totalPage - 2, this.totalPage - 1, this.totalPage]];
      } else {
        this.splitPage = [[1], [-1], [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2], [-1], [this.totalPage]];
      }
      console.log(this.splitPage);
    },
    onClickPrePage() {
      if (this.currentPage <= 1) {
        return;
      }
      this.onPageChange(this.currentPage - 1);
    },
    onClickNextPage() {
      if (this.currentPage >= this.totalPage) {
        return;
      }
      this.onPageChange(this.currentPage + 1);
    },
    onClickPage(page) {
      if (page == this.currentPage) {
        return;
      }
      this.onPageChange(page);
    },
    onPageChange(page) {
      this.$emit('onPageChange', page);
    }
  }
};