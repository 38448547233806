import Vue from "vue";
import VueI18n from "vue-i18n";
const { crc32 } = require("crc");
const { langDefault, langList } = require('@i18n/i18nOption.json')
const langObj = {}
langList.map(item => {
  langObj[item.value] = require(`@i18n/json/${item.value}.json`)
})

Vue.use(VueI18n);

let localLang = localStorage.getItem("lang"); // 业务需要存放仓库, 如element国际化, 需要刷新页面重新加载, 在main.js初始化element国际化语言. 这里根据你的业务去做语言切换功能.
if(!localLang && process.env.VUE_APP_AREA !== 'china'){
  localLang = "En"
  localStorage.setItem("lang",localLang)
}

//实例化语言类
const i18n = new VueI18n({
  locale: localLang || langDefault,
  fallbackLocale: localLang || langDefault,
  messages: langObj,
  silentTranslationWarn: true,
});

// --------这里是i18next-scanner新增的配置-------------
function lang(key) {
  let hashKey = `K${crc32(key).toString(16)}`; // 将中文转换成crc32格式去匹配对应的json语言包
  let words = i18n.t(hashKey);
  if (words == hashKey) {
    words = key;
    console.log(key, "-无匹配语言key");
  }
  return words;
}
Vue.prototype.i18n = i18n
Vue.prototype.$lang = lang; // 注入全局, 页面$lang('xxx')调用
// --------这里是i18next-scanner新增的配置-------------

export default i18n;