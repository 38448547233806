var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "layout-input-numb-cal",
    class: _vm.isMobile && 'wap-layout-input-numb-cal'
  }, [_c('div', {
    class: {
      'input-button-limit': true,
      'input-button-limit-enable': _vm.inputButtonLimitMinEnable,
      'input-button-limit-disable': !_vm.inputButtonLimitMinEnable
    },
    on: {
      "click": _vm.onClickMin
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Min')) + " ")]), _c('div', {
    class: {
      'input-button-action': true,
      'input-button-action-disable': !_vm.inputButtonSubEnable
    },
    on: {
      "click": _vm.onClickSub
    }
  }, [_vm._v(" - ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputValue,
      expression: "inputValue"
    }],
    staticClass: "input-center",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.inputValue
    },
    on: {
      "focusout": _vm.checkAndNofity,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.inputValue = $event.target.value;
      }
    }
  }), _c('div', {
    class: {
      'input-button-action': true,
      'input-button-action-disable': !_vm.inputButtonAddEnable
    },
    on: {
      "click": _vm.onClickAdd
    }
  }, [_vm._v(" + ")]), _c('div', {
    class: {
      'input-button-limit': true,
      'input-button-limit-enable': _vm.inputButtonLimitMaxEnable,
      'input-button-limit-disable': !_vm.inputButtonLimitMaxEnable
    },
    on: {
      "click": _vm.onClickMax
    }
  }, [_vm._v(" " + _vm._s(_vm.$lang('Max')) + " ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };