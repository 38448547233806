import CommonTipsDialog from "@components/CommonTipsDialog.vue";
export default {
  components: {
    CommonTipsDialog
  },
  props: {},
  mounted() {},
  destroyed() {},
  computed: {},
  data() {
    return {
      visible: false
    };
  },
  watch: {},
  methods: {
    cancelFn() {
      this.$emit("cancel");
    },
    confirmFn() {
      this.$emit("confirm");
    }
  }
};