import Vue from "vue";

export function errcode(err) {
  const toast = (content) => {
    Vue.toasted.error(content);
  };
  const Errcode = {
    10001: Vue.prototype.$lang("[10001] System error"),
    10002: Vue.prototype.$lang("[10002] Parameter error"),
    10003: Vue.prototype.$lang("[10003] Not authorized to access"),
    10004: Vue.prototype.$lang("[10004] User does not exist"),
    10005: Vue.prototype.$lang("[10005] User already exists"),
    10006: Vue.prototype.$lang("[10006] The user is not logged in or the login timed out"),
    10007: Vue.prototype.$lang("[10007] User password error"),
    10008: Vue.prototype.$lang("[10008] Verification code error"),
    10009: Vue.prototype.$lang("[10009] Offending account"),
    10010: Vue.prototype.$lang("[10010] wrong password format"),
    10101: Vue.prototype.$lang("[10101] The data already exists"),
    10102: Vue.prototype.$lang("[10102] The data does not exist"),
    10103: Vue.prototype.$lang("[10103] The data is invalid"),
    10104: Vue.prototype.$lang("[10104] The data has expired"),
    10105: Vue.prototype.$lang("[10105] The data has used"),
    10106: Vue.prototype.$lang("[10106] The data is lack"),
    10107: Vue.prototype.$lang("[10107] The data is locked"),
    10199: Vue.prototype.$lang("[10199] The data has changed"),
    10200: Vue.prototype.$lang("[10200] File format not allowed"),
  };
  for (const key in Errcode) {
    if (err == key) {
      toast(Errcode[key]);
    }
  }
}

export default function vailCode(rs, callback, errcallback) {
  if (rs.code == 0) {
    typeof callback == "function" && callback(rs);
  } else {
    Vue.toasted.error(rs.message);
    typeof errcallback == "function" && errcallback(rs);
    Vue.prototype.$loading.hide();
  }
}
