import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
import InputNumCalView from "@components/InputNumCalView.vue";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    InputNumCalView
  },
  computed: {
    ...walletVuex.mapState(["isMobile"])
  },
  props: {
    nftTotalSalNum: {
      type: Number,
      default: 0
    },
    nftSalePrice: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      inputNumber: 1
    };
  },
  methods: {
    updateInputNumber(value) {
      this.inputNumber = value;
    },
    toNftMall() {
      this.$router.push('/nft');
    }
  }
};