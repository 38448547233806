import { createNamespacedHelpers } from "vuex";
import BigNumber from "bignumber.js";
import web3Config from "@Web3WalletConfig/config.json";
import web3Wallet from "@web3Wallet";
import { showCommonTipsDialog, showCommonTipsErrorDialog } from "@components/CommonDialog";
import { BaseError } from "viem";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  props: {
    dialogVisible: Boolean
  },
  data() {
    return {
      inputAmount: 0
    };
  },
  watch: {
    dialogVisible: {
      handler(newValue, oldValue) {}
    }
  },
  mounted() {},
  methods: {
    onConfirm() {
      if (this.inputAmount <= 0) {
        showCommonTipsErrorDialog(this.$lang('hint'), this.$lang('Please input correct amount'));
        return;
      }
      this.$loading.show();

      //check approve
      const price = new BigNumber(this.inputAmount).times(10 ** web3Config.rect_token_decimals).toFixed(0);
      const params = [web3Wallet.address, web3Config.recharge_withdraw_contract];
      web3Wallet.contract("token", web3Config.rect_token_contract).call("allowance", params).then(data => {
        console.log(data);
        if (new BigNumber(data).gte(price)) {
          return 1;
        } else {
          const params = [web3Config.recharge_withdraw_contract, price];
          return web3Wallet.contract("token", web3Config.rect_token_contract).send("approve", params);
        }
      }).then(data => {
        console.log(data);
        const params = [web3Config.rect_token_contract, price];
        return web3Wallet.contract("rechargeAndWithdrawContract", web3Config.recharge_withdraw_contract).send("recharge", params);
      }).then(() => {
        this.$loading.hide();
        return showCommonTipsDialog(this.$lang('success'), this.$lang('Recharge successful'));
      }).then(() => {
        this.inputAmount = 0;
        this.$emit('confirm');
      }).catch(e => {
        console.error('recharge error', e);
        if (e instanceof BaseError) {
          //合约错误
          showCommonTipsErrorDialog(this.$lang('hint'), e.shortMessage);
        }
      }).finally(() => {
        this.$loading.hide();
      });
    }
  }
};