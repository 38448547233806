var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('CommonTipsDialog', {
    attrs: {
      "custom-slot-visible": _vm.visible,
      "title": _vm.$lang('hint'),
      "tips": _vm.$lang('市场账户RECT手续费余额不足')
    },
    on: {
      "cancel": _vm.cancelFn,
      "confirm": _vm.confirmFn
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function () {
        return [_c('div', {
          staticClass: "dialog-actions"
        }, [_c('div', {
          staticClass: "dialog-button btn-cancel",
          on: {
            "click": _vm.cancelFn
          }
        }, [_vm._v(" " + _vm._s(_vm.$lang("Cancel")) + " ")]), _c('div', {
          staticClass: "dialog-button btn-confirm",
          on: {
            "click": _vm.confirmFn
          }
        }, [_vm._v(" " + _vm._s(_vm.$lang("前往划转")) + " ")])])];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };