import Vue from "vue";
import vailcode from "@utils/errcode";
import { createNamespacedHelpers } from "vuex";
import moment from "moment/moment";
import { formatRectCoinAmount } from "@utils";
import { showCommonTipsDialog, showCommonTipsErrorDialog } from "@components/CommonDialog";
import web3Wallet from "@web3Wallet";
import web3Config from "@Web3WalletConfig/config.json";
import { BaseError } from "viem";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  props: {
    dialogVisible: Boolean
  },
  data() {
    return {
      recordList: [],
      logLoading: false,
      logHasMore: false,
      page: 1,
      showFilter: false,
      filterType: '',
      tabType: 1 //1, 2, 3
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "connect", "address"])
  },
  watch: {
    dialogVisible: {
      handler(newVal, oldVal) {
        this.page = 1;
        if (newVal) {
          this.onLoadData(1);
        }
      },
      immediate: true
    }
  },
  methods: {
    checkScroll() {
      const scrollHeight = this.$refs.myScroll.scrollHeight;
      const scrollTop = this.$refs.myScroll.scrollTop;
      const clientHeight = this.$refs.myScroll.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        // 接近底部时触发加载
        if (this.logHasMore && !this.logLoading) {
          let page = this.page;
          this.onLoadData(page + 1);
        }
      }
    },
    showFilterMenu() {
      this.showFilter = !this.showFilter;
    },
    hideFilterMenu(type) {
      this.filterType = type;
      this.showFilter = false;
      this.onLoadData(1);
    },
    onTabChange(type) {
      this.tabType = type;
      this.recordList = [];
      this.onLoadData(1);
    },
    async onLoadData(page) {
      if (!this.address) {
        return;
      }
      if (this.logLoading) {
        return;
      }
      this.logLoading = true;
      this.page = page;
      if (this.tabType == 1) {
        // staker/recharge/logs
        await this.recordRecharge(page);
      } else if (this.tabType == 2) {
        await this.recordWithdraw(page);
      } else if (this.tabType == 3) {
        await this.recordTransfer(page);
      } else if (this.tabType == 4) {
        await this.recordXiaoFei(page);
      }
    },
    async recordRecharge(page) {
      let params = {
        page: page,
        pageSize: 15,
        "owner": this.address,
        "assets_type": ''
      };
      let rs = await Vue.prototype.$http.post("staker/recharge/logs", params);
      vailcode(rs, () => {
        rs.data.list.map(item => {
          console.log(item);
          item.showDateFormat = moment(item.created_at).format('yyyy-MM-DD HH:mm:ss');
          item.formatAmount = formatRectCoinAmount(item.amount);
        });
        if (rs.data.pageNo == 1) {
          this.recordList = rs.data.list;
        } else {
          this.recordList = this.recordList.concat(rs.data.list);
        }
        if (rs.data.totalPage > rs.data.pageNo) {
          this.logHasMore = true;
        } else {
          this.logHasMore = false;
        }
        this.logLoading = false;
      }, () => {
        this.logLoading = false;
      });
    },
    async recordWithdraw(page) {
      let params = {
        page: page,
        pageSize: 15,
        "owner": this.address,
        "assets_type": ''
      };
      let rs = await Vue.prototype.$http.post("staker/withdraw/logs", params);
      vailcode(rs, () => {
        rs.data.list.map(item => {
          console.log(item);
          item.showDateFormat = moment(item.created_at).format('yyyy-MM-DD HH:mm:ss');
          item.formatAmount = formatRectCoinAmount(item.amount);
        });
        if (rs.data.pageNo == 1) {
          this.recordList = rs.data.list;
        } else {
          this.recordList = this.recordList.concat(rs.data.list);
        }
        if (rs.data.totalPage > rs.data.pageNo) {
          this.logHasMore = true;
        } else {
          this.logHasMore = false;
        }
        this.logLoading = false;
      }, () => {
        this.logLoading = false;
      });
    },
    async recordTransfer(page) {
      let params = {
        page: page,
        pageSize: 15,
        "owner": this.address,
        "assets_type": ''
      };
      let rs = await Vue.prototype.$http.post("staker/exchange/logs", params);
      vailcode(rs, () => {
        rs.data.list.map(item => {
          console.log(item);
          item.showDateFormat = moment(item.created_at).format('yyyy-MM-DD HH:mm:ss');
          item.formatAmount = formatRectCoinAmount(item.amount);
        });
        if (rs.data.pageNo == 1) {
          this.recordList = rs.data.list;
        } else {
          this.recordList = this.recordList.concat(rs.data.list);
        }
        if (rs.data.totalPage > rs.data.pageNo) {
          this.logHasMore = true;
        } else {
          this.logHasMore = false;
        }
        this.logLoading = false;
      }, () => {
        this.logLoading = false;
      });
    },
    async recordXiaoFei(page) {
      let params = {
        page: page,
        pageSize: 15,
        "owner": this.address
      };
      let rs = await Vue.prototype.$http.post("staker/consume/logs", params);
      vailcode(rs, () => {
        rs.data.list.map(item => {
          console.log(item);
          item.showDateFormat = moment(item.created_at).format('yyyy-MM-DD HH:mm:ss');
          if (item.action == 'sell') {
            item.formatAmount = item.price - item.fee;
          } else if (item.action == 'buy') {
            item.formatAmount = item.price;
          } else if (item.action == 'transferOut') {
            item.formatAmount = item.fee;
          }
        });
        if (rs.data.pageNo == 1) {
          this.recordList = rs.data.list;
        } else {
          this.recordList = this.recordList.concat(rs.data.list);
        }
        if (rs.data.totalPage > rs.data.pageNo) {
          this.logHasMore = true;
        } else {
          this.logHasMore = false;
        }
        this.logLoading = false;
      }, () => {
        this.logLoading = false;
      });
    },
    withdrawOldOrder(item) {
      this.$loading.show();
      let params = {
        owner: this.address,
        order_id: item.order_id
      };
      Vue.prototype.$http.post("staker/order/withdraw", params).then(data => {
        if (data.code != 0) {
          showCommonTipsErrorDialog(this.$lang('hint'), data.message);
          throw new Error(data.message);
        }
        data = data.data;
        console.log(data);
        // "token": "0xc5775611042682F5E734543067602E7749423b65",
        //     "orderId": "20241207065228656142",
        //     "timestamp": 1733554348,
        //     "withdrawAmount": "1000000000000000000",
        //     "seeds": [
        //   "0x60b9178b552e4a2ed93f9022b5cea5778a3c7c326b6b3dfe2ac02c97c5ae47d3",
        //   "0x75ee5f5fda5d9b37432a9c47a6aa5042d5be6e3dd223dc83737b3c91980a17b3",
        //   27
        // ]
        //请求合约
        const params = [data.token, data.orderId, data.withdrawAmount, data.timestamp, data.seeds];
        return web3Wallet.contract("rechargeAndWithdrawContract", web3Config.recharge_withdraw_contract).send("withdraw", params);
      }).then(data => {
        // console.log(data)

        this.$loading.hide();
        this.onLoadData(1);
        return showCommonTipsDialog(this.$lang('success'), this.$lang('Withdraw successful'));
      }).then(() => {}).catch(e => {
        console.error('recharge error', e);
        if (e instanceof BaseError) {
          //合约错误
          showCommonTipsErrorDialog(this.$lang('hint'), e.shortMessage);
        }
      }).finally(() => {
        this.$loading.hide();
      });
    }
  }
};