import { createNamespacedHelpers } from "vuex";
import web3Wallet from "@web3Wallet";
const walletVuex = createNamespacedHelpers("wallet");
const stakeRecordVuex = createNamespacedHelpers("stakeRecord");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "connect", "address"]),
    ...stakeRecordVuex.mapState(["recordList", "condition", "logLoading", "logHasMore"])
  },
  data() {
    return {
      stakeType: 2,
      showRecordDialog: false,
      unStakeLoading: {}
    };
  },
  methods: {
    ...stakeRecordVuex.mapActions(["getRecordList"]),
    ...stakeRecordVuex.mapMutations(["setCondition"]),
    checkScroll() {
      const scrollHeight = this.$refs.myScroll.scrollHeight;
      const scrollTop = this.$refs.myScroll.scrollTop;
      const clientHeight = this.$refs.myScroll.clientHeight;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        // 接近底部时触发加载
        if (this.logHasMore && !this.logLoading) {
          let page = this.condition.page;
          this.onLoadData(page + 1);
        }
      }
    },
    onLoadData(page) {
      this.setCondition({
        "owner": this.address,
        // "owner": '0x70A44Bec4231635521C3809FAc18B9B792Da6318',
        "stake_type": this.stakeType,
        "pageSize": 15,
        "page": page
      });
      this.getRecordList([() => {}, () => {}]);
    },
    showDialog() {
      this.showRecordDialog = true;
      this.onLoadData(1);
    },
    switchStakeType(type) {
      this.stakeType = type;
      this.onLoadData(1);
    },
    doUnStake(item) {
      if (this.unStakeLoading[item.id]) return;
      this.unStakeLoading[item.id] = true;
      const params = [item.order_id];
      web3Wallet.contract("coinStake").send("unStake", params).then(() => {
        this.$toasted.success(this.$lang('unStake submission successful'));
        this.unStakeLoading[item.id] = false;
      }).catch(() => {
        this.unStakeLoading[item.id] = false;
      });
    }
  }
};