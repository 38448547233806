import { createNamespacedHelpers } from "vuex";
import InputNumCalView from "@components/InputNumCalView.vue";
import { showCommonTipsDialog } from "@components/CommonDialog";
import web3Wallet from "@web3Wallet";
import Vue from "vue";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    InputNumCalView
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "address"])
  },
  props: {
    nftAvailableNum: {
      type: Number,
      default: 0
    },
    nftLockedNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      inputConsignmentNumber: 1,
      inputRemoveNumber: 1,
      inputWalletAddr: ''
    };
  },
  methods: {
    updateInputNumber(value) {
      this.inputConsignmentNumber = value;
    },
    updateRemoveNumber(value) {
      this.inputRemoveNumber = value;
    },
    confirmConsignment() {
      if (this.nftAvailableNum <= 0 || this.inputConsignmentNumber > this.nftAvailableNum) {
        showCommonTipsDialog(this.$lang('hint'), this.$lang('Not owning enough first-generation NFTs')).then(() => {}).catch(() => {});
        return;
      }
      let tips = this.$options.filters['sprintf'](this.$lang('Consign <span>%s</span> first-generation NFTs. After the consignment is successful, each first-generation NFT will be charged a sales fee of <span>%s RECT</span>.'), this.inputConsignmentNumber, 50);
      showCommonTipsDialog(this.$lang('consignment'), tips, true).then(() => {
        this.$loading.show();
        //sign message
        let message = 'consignment nft action';
        return web3Wallet.signMessage(message);
      }).then(signed => {
        let params = {
          owner: this.address,
          count: this.inputConsignmentNumber,
          signed: signed,
          signedMsg: 'consignment nft action'
        };
        return Vue.prototype.$http.post("nft/sale", params);
      }).then(data => {
        this.$loading.hide();
        if (data.code == 0) {
          this.$emit('consignmentSuccess');
          return showCommonTipsDialog(this.$lang('success'), this.$lang('Consignment successful'));
        } else {
          return showCommonTipsDialog(this.$lang('hint'), data.message);
        }
      }).then(() => {}).catch(() => {}).finally(() => {
        this.$loading.hide();
      });
    },
    confirmRemove() {
      if (this.nftLockedNum <= 0 || this.inputRemoveNumber > this.nftLockedNum) {
        showCommonTipsDialog(this.$lang('hint'), this.$lang('Insufficient quantity of first-generation NFT in consignment')).then(() => {}).catch(() => {});
        return;
      }
      let tips = this.$options.filters['sprintf'](this.$lang('Remove <span>%s</span> of first-generation NFTs from the shelves'), this.inputRemoveNumber);
      showCommonTipsDialog(this.$lang('Removed'), tips, true).then(() => {
        this.$loading.show();
        //sign message
        let message = 'remove nft action';
        return web3Wallet.signMessage(message);
      }).then(signed => {
        let params = {
          owner: this.address,
          count: this.inputRemoveNumber,
          signed: signed,
          signedMsg: 'remove nft action'
        };
        return Vue.prototype.$http.post("nft/unsale", params);
      }).then(data => {
        this.$loading.hide();
        if (data.code == 0) {
          this.$emit('removeSuccess');
          return showCommonTipsDialog(this.$lang('success'), this.$lang('Removed successful'));
        } else {
          return showCommonTipsDialog(this.$lang('hint'), data.message);
        }
      }).then(() => {}).catch(() => {}).finally(() => {
        this.$loading.hide();
      });
    }
  }
};