import web3Wallet from "@web3Wallet";
import web3Config from "@Web3WalletConfig/config";
import BigNumber from "bignumber.js";
import vailcode from "@utils/errcode";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        rectCoinBalance: 0
    },
    mutations: {
        setRectCoinBalance: (state, payload) => {
            state.rectCoinBalance = payload
        }
    },
    actions: {
        getRectCoinBalance: async ({commit, dispatch}) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const res = await web3Wallet.contract('token', web3Config.rect_token_contract).call("balanceOf", [web3Wallet.address])
                    console.log('get rect coin balance', res)
                    commit("setRectCoinBalance", new BigNumber(res).div(10 ** web3Config.rect_token_decimals))
                    resolve()
                } catch (err) {
                    console.log("getRectCoinBalance===", err);
                    resolve()
                }
            })
        },
        getStakeCoinPreGetResult: async ({state, commit, dispatch}, [amount, stakeType ,callback]) => {

           // state.abortCtl.abort();
            let params = {
                amount: amount,
                stake_type: stakeType
            }
            let rs = await Vue.prototype.$http.post("stake/calc/coin/hashrate" ,params);
            vailcode(rs,async()=>{
                typeof callback == 'function' && callback(rs)
            })
        },
    }
}