import { createNamespacedHelpers } from "vuex";
import moment from "moment/moment";
import CommonAnimButton from "@components/CommonAnimButton.vue";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    CommonAnimButton
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  props: {
    nftItem: {
      type: Object,
      default: {}
    },
    nftSalePrice: {
      type: Number,
      default: 0
    }
  },
  methods: {
    onConfirm() {
      this.$emit('onConfirm', this.nftItem);
    },
    formatDate(date) {
      return moment(date).format('yyyy-MM-DD HH:mm:ss');
    }
  }
};