var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": _vm.isMobile ? '90vw' : '500px'
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop__intro"
  }, [_vm._v(_vm._s(_vm.$lang("Record")))]), _c('div', {
    staticClass: "pop-log-list"
  }, [_c('div', {
    ref: "myScroll",
    staticClass: "pop-log-box",
    class: _vm.isMobile && 'wap',
    on: {
      "scroll": _vm.checkScroll
    }
  }, [!_vm.claimList || _vm.claimList.length <= 0 ? [!_vm.logLoading ? _c('EmptyLayout') : _vm._e()] : _vm._e(), _vm._l(_vm.claimList, function (item) {
    return _c('div', {
      staticClass: "pop-log-item"
    }, [_c('div', {
      staticClass: "col"
    }, [_c('div', {
      staticClass: "token"
    }, [_vm._v(" " + _vm._s(item.amount_format) + " $ RECT ")]), _c('div', {
      staticClass: "time"
    }, [_vm._v(" " + _vm._s(_vm._f("timeFormat")(item.created_at)) + " ")])]), _c('div', {
      staticClass: "col status",
      class: `status-${item.status}`
    }, [item.status == 0 ? [item.is_lock == 1 ? [_vm._v(_vm._s(_vm.$lang('Process')))] : _vm._e(), item.is_lock == 0 ? [_c('div', {
      staticClass: "btn",
      on: {
        "click": function ($event) {
          return _vm.onClaim(item);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$lang(`Claim`)) + " ")])] : _vm._e()] : _vm._e(), item.status == 1 ? [_vm._v(" " + _vm._s(_vm.$lang('Processing')) + " ")] : _vm._e(), item.status == 2 ? [_vm._v(" " + _vm._s(_vm.$lang('Success')) + " ")] : _vm._e(), item.status == 3 ? [_vm._v(" " + _vm._s(_vm.$lang('Failure')) + " ")] : _vm._e()], 2)]);
  }), _vm.logLoading ? _c('div', {
    staticClass: "loadingList"
  }, [_c('div', {
    staticClass: "loading-list"
  }, _vm._l(3, function (_) {
    return _c('div');
  }), 0)]) : _vm._e()], 2)]), _c('div', {
    staticClass: "close-btn",
    on: {
      "click": function ($event) {
        _vm.$parent.popOrderShow = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$lang('Close')))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };