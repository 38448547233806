import { createNamespacedHelpers } from "vuex";
import Vue from "vue";
import { formatRectCoinAmount } from "@utils";
import { showCommonTipsDialog, showCommonTipsErrorDialog } from "@components/CommonDialog";
import web3Wallet from "@web3Wallet";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  props: {
    dialogVisible: Boolean
  },
  data() {
    return {
      fromType: '',
      toType: '',
      inputAmount: 0,
      showSelectedFrom: false,
      showSelectedTo: false,
      accountBalance: 0
    };
  },
  watch: {
    dialogVisible: {
      handler(newValue, oldValue) {
        this.getAccountBalance();
      }
    }
  },
  mounted() {},
  methods: {
    checkInputAmountLimit() {
      console.log('check limit', this.inputAmount, this.accountBalance);
      if (this.inputAmount <= 0) {
        this.inputAmount = 0;
      } else if (this.inputAmount > this.accountBalance) {
        this.inputAmount = this.accountBalance;
      }
    },
    onConfirm() {
      if (this.fromType == this.toType || this.fromType == '' || this.toType == '') {
        showCommonTipsErrorDialog(this.$lang('hint'), this.$lang('Please select correct account type'));
        return;
      }
      if (this.inputAmount <= 0 || this.inputAmount > this.accountBalance) {
        showCommonTipsErrorDialog(this.$lang('hint'), this.$lang('Please input correct amount'));
        return;
      }
      this.$loading.show();
      //sign message
      let message = 'transfer amount action';
      web3Wallet.signMessage(message).then(signed => {
        let params = {
          owner: this.address,
          amount: this.inputAmount,
          from_assets_type: this.fromType,
          to_assets_type: this.toType,
          signed: signed,
          signedMsg: 'transfer amount action'
        };
        return Vue.prototype.$http.post("staker/exchange", params);
      }).then(data => {
        this.$loading.hide();
        if (data.code == 0) {
          this.$emit('confirm');
          return showCommonTipsDialog(this.$lang('success'), this.$lang('transfer successful'));
        } else {
          return showCommonTipsDialog(this.$lang('hint'), data.message);
        }
      }).then(() => {}).catch(() => {}).finally(() => {
        this.$loading.hide();
      });
    },
    selectedFromType(type) {
      this.fromType = type;
      this.showSelectedFrom = false;
      this.getAccountBalance();
    },
    selectedToType(type) {
      this.toType = type;
      this.showSelectedTo = false;
    },
    getAccountBalance() {
      this.$loading.show();
      let params = {
        "owner": this.address
      };
      Vue.prototype.$http.post("staker/info", params).then(data => {
        data = data.data;
        if (this.fromType == 'amount') {
          this.accountBalance = formatRectCoinAmount(data.amount);
        } else if (this.fromType == 'rect_amount') {
          this.accountBalance = formatRectCoinAmount(data.rect_amount);
        } else {
          this.accountBalance = 0;
        }
      }).catch(() => {}).finally(() => {
        this.$loading.hide();
      });
    }
  }
};