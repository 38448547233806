import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
import InputNumCalView from "@components/InputNumCalView.vue";
import { showCommonTipsDialog, showRectBalanceNotEnoughDialog } from "@components/CommonDialog";
import Vue from "vue";
import web3Wallet from "@web3Wallet";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  components: {
    InputNumCalView
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "address"])
  },
  props: {
    nftAvailableNum: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      inputNumber: 1,
      inputWalletAddr: ''
    };
  },
  methods: {
    updateInputNumber(value) {
      this.inputNumber = value;
    },
    confirmTransfer() {
      if (this.nftAvailableNum <= 0 || this.inputNumber > this.nftAvailableNum) {
        showCommonTipsDialog(this.$lang('hint'), this.$lang('Not owning enough first-generation NFTs')).then(() => {}).catch(() => {});
        return;
      }
      if (!this.inputWalletAddr || this.inputWalletAddr.length < 10) {
        showCommonTipsDialog(this.$lang('hint'), this.$lang('Please input receiver wallet address')).then(() => {}).catch(() => {});
        return;
      }
      let tips = this.$options.filters['sprintf'](this.$lang('Transfer <span>%s</span> first-generation NFTs to %s'), this.inputNumber, this.inputWalletAddr.slice(0, 4) + '****' + this.inputWalletAddr.slice(-4));
      showCommonTipsDialog(this.$lang('Transfer'), tips, true).then(() => {
        this.$loading.show();
        //sign message
        let message = 'transfer nft action';
        return web3Wallet.signMessage(message);
      }).then(signed => {
        let params = {
          owner: this.address,
          recipient: this.inputWalletAddr,
          count: this.inputNumber,
          signed: signed,
          signedMsg: 'transfer nft action'
        };
        return Vue.prototype.$http.post("nft/transfer", params);
      }).then(data => {
        this.$loading.hide();
        if (data.code == 0) {
          this.$emit('transferSuccess');
          return showCommonTipsDialog(this.$lang('success'), this.$lang('Transfer successful'));
        } else if (data.code == -10) {
          return showRectBalanceNotEnoughDialog().then(() => {
            this.$router.push('/profile');
          });
        } else {
          return showCommonTipsDialog(this.$lang('hint'), data.message);
        }
      }).then(() => {}).catch(() => {}).finally(() => {
        this.$loading.hide();
      });
    }
  }
};