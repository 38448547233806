import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  computed: {
    ...walletVuex.mapState(["isMobile", "connect", "address"])
  },
  data() {
    return {
      showFilter: false,
      filterTypeShow: this.$lang('Default'),
      filterType: 'default',
      descType: 'desc'
    };
  },
  methods: {
    hideFilterMenu(filterType, descType) {
      this.filterType = filterType;
      this.descType = descType;
      if (this.filterType == 'locked_num') {
        this.filterTypeShow = this.$lang('Sales');
      } else if (this.filterType == 'sale_time') {
        this.filterTypeShow = this.$lang('time');
      } else {
        this.filterTypeShow = this.$lang('Default');
      }
      this.showFilter = !this.showFilter;
      if (filterType == 'default') filterType = 'sale_time';
      this.$emit('filter', filterType, descType);
    }
  }
};