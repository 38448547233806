import loadConfig from "@Web3WalletConfig/config.json";
import BigNumber from "bignumber.js";
import vailcode from "@utils/errcode";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        recordList: [],
        logHasMore: false,
        logLoading: false,
        condition: {
            page: 1,
            pageSize: 15
        }
    },
    mutations: {
        setCondition: (state, payload) => {
            state.condition = payload
        },
        setLogLoading: (state, payload) => {
            state.logLoading = payload
        },
        setLogHasMore: (state, payload) => {
            state.logHasMore = payload
        },
        setRecordList: (state, payload) => {
            state.recordList = payload;
        },
        appendRecordList: (state, payload) => {
            state.recordList = state.recordList.concat(payload)
        },
    },
    actions: {
        getRecordList: async ({state, commit, dispatch}) => {
            if (state.logLoading) return;
            commit('setLogLoading', true);
            commit("setRecordList", []);
            let params = state.condition
            let rs = await Vue.prototype.$http.post("stake/coin/list", params);
            vailcode(rs, () => {
                    rs.data.list.map(item => {
                        item.amount_format = BigNumber(item.amount).div(10 ** loadConfig.rect_token_decimals).toFixed(2)
                        item.isLoading = false
                        return item;
                    })
                    if (rs.data.pageNo == 1) {
                        commit("setRecordList", rs.data.list);
                    } else {
                        commit('appendRecordList', rs.data.list);
                    }
                    if (rs.data.totalPage > rs.data.pageNo) {
                        commit('setLogHasMore', true);
                    } else {
                        commit('setLogHasMore', false);
                    }
                    commit('setLogLoading', false);
                    typeof callback == 'function' && callback(rs.data)
                },
                () => {
                    commit('setLogLoading', false);
                    typeof failcallback == 'function' && failcallback(rs)
                });
        },
    }
}